.breadcrumb {
    background: none;
    font-size: calc(14rem/16);
    margin-top: calc(20rem/16);
}

.breadcrumb li.active span {
    color: var(--color-primary);
}

.breadcrumb li:not(:last-child) a:after {
    font-family: "iconfont";
    content: var(--icon-rolex-arrow-right);
    font-size: calc(8rem/16);
    margin: 0 calc(5rem/16);
}

.rolex-breadcrumb{
    padding-left: 5vw;
    padding-right: 5vw;
}

.rolex-breadcrumb .breadcrumb{
    font-size: calc(11rem/16);
    margin-top: calc(5rem/16);
    margin-bottom: 0;

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
    }
}

.rolex-breadcrumb .breadcrumb--mobile li a:before {
    font-family: "iconfont";
    content: var(--icon-rolex-arrow-right);
    display: inline-block;
    margin: 0 calc(5rem/16);
    font-size: calc(8rem/16);
    transform: rotate(180deg);
}