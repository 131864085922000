.product-teaser-details {
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(50rem/16);
    }
}

.product-teaser-details .control-label {
    /* reset general control-label styles */
    position: relative;
    left: 0;
    transform: none;
    color: var(--color-text-default);

    display: block;
    font-family: var(--font-default);
    font-size: calc(16rem/16);
    font-weight: bold;
    padding: calc(22rem/16) calc(20rem/16) calc(10rem/16) 0;

    @media screen and (min-width: 992px) {
        font-size: calc(18rem/16);
    }
}

.product-teaser-details .form-control {
    padding: 0;
    margin-bottom: calc(20rem/16);
    font-size: calc(18rem/16);
}

.product-teaser-details__hl {
    text-transform: uppercase;
    font-size: calc(30rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(50rem/16);
    }
}

.product-teaser-details .form-group select.form-control {
    padding-left: calc(20rem/16);
    padding-right: calc(40rem/16);
}

.product-teaser-details button.btn-modell {
    @media screen and (max-width: 992px){
        margin-bottom: calc(40rem/16);
    }
}

.product-detail__desc {
    margin-bottom: calc(20rem/16);
}

.product-detail-slider{
    overflow: hidden;
}

.product-detail-slider__big{
    position: relative;
}

.product-detail-slider button {
    background: var(--color-primary-dark);
    border: 0;
    color: #fff;
    padding: calc(5rem/16) calc(15rem/16);
    position: absolute;
    bottom: 0;
    z-index: 50;
}

.product-detail-slider .slick-dots button {
    bottom: calc((80rem/16)*-1);
}

.product-detail-slider button:hover {
    background: var(--color-primary);
}

.product-detail-slider .slick-arrow .icon{
    @media screen and (max-width: 767px){
       vertical-align: calc(-3rem/16);
    }
}

.product-detail-slider__thumbs {
    margin-top: calc(20rem/16);
}

.product-detail-slider__thumbs__img img {
    height: calc(100rem/16);
    width: calc(100rem/16);
    margin-right: calc(20rem/16);
}

.product-detail-slider__thumbs img:hover {
    cursor: pointer;
}

.product-detail-slider__thumbs .slick-current img {
    border: calc(1rem/16) solid var(--color-primary-dark);
    padding: calc(5rem/16);
}

.product-detail-slider__thumbs .slick-list {
    margin-right: calc((20rem/16)*-1);
}

.product-teaser-details .detail-section {
    margin-top: calc(15rem/16);
}

.product-detail-slider button.slick-prev {
    right: calc(55rem/16);
    border-right: calc(2rem/16) solid var(--color-primary);
    height: calc(40rem/16);
}

.product-detail-slider button.slick-next {
    right: 0;
    height: calc(40rem/16);
}

.product-teaser-details__btns .btn {
    margin-top: calc(15rem/16);
}

.product-detail__price{
    font-family: var(--font-bold);
    font-size: 20px;
}

.product-detail__price-reduced{
    text-decoration: line-through;
    margin-left: 20px;
}
/*.product-detail-slider .slick-slide img {
    !* hide slide until it is active - else there will occur layout problems on mobile devices*!
    display: none !important;
}*/

/*
.product-detail-slider .slick-current img, .product-detail-slider .slick-active img {
     display: inline-block !important;
 }*/


.product-detail-slider__zoom-info{
    font-size: calc(12rem/16);
    position: absolute;
    bottom: 5px;
    left: 10px;
    pointer-events: none;
}

.product-detail-slider__zoom-info .icon{
    vertical-align: -2px;
    margin-right: 3px;
    color: var(--color-primary-dark);
}