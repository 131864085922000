body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(14rem/16);
    line-height: calc(26/14);
    padding-bottom: 0;

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
        line-height: calc(30/18);
    }
}

b,
strong,
.strong {
    font-family: var(--font-bold);
    font-weight: normal;
}

h1, h2, h3,
.h1, .h2, .h3 {
    margin-top: 0;
    display: block;
}

h1, .h1 {
    font-size: calc(50rem/16);
    line-height: calc(47/50);
    font-family: var(--font-regular);
}
h2, .h2 {
    font-size: calc(33rem/16);
    line-height: calc(39/33);
    font-family: var(--font-regular);
}
h3, .h3 {
    font-size: calc(25rem/16);
    line-height: calc(29/25);
    margin-bottom: calc(20rem/16);
    font-family: var(--font-regular);
}
h4, .h4 {
    font-size: calc(20rem/16);
    line-height: calc(22/20);
    text-transform: uppercase;
    margin-bottom: calc(10rem/16);
    font-family: var(--font-bold);
    letter-spacing: 3px;
}


@media screen and (min-width: 992px){
    /*  h1, .h1 {
          font-size: calc(130rem/16);
          line-height: calc(146/130);
      }*/
    h2, .h2 {
        font-size: calc(60rem/16);
        line-height: calc(68/60);
    }
    h3, .h3 {
        font-size: calc(40rem/16);
        line-height: calc(50/40);
    }
}

@media screen and (min-width: 768px){
    h1, .h1 {
        /* font-size: calc(80rem/16);
         line-height: calc(100/80);*/

        font-size: responsive calc(80rem/16) calc(130rem/16); /* min-size, max-size */
        font-range: 768px 1700px; /* viewport widths between which font-size is fluid */
    }
    h2, .h2 {
        font-size: calc(50rem/16);
        line-height: calc(60/50);
    }
    h3, .h3 {
        font-size: calc(30rem/16);
        line-height: calc(38/30);
    }
}

.fz15{
    font-size: calc(15rem/16);
}

.big-font {
    font-size: calc(130rem/16);

    @media screen and (min-width: 1200px){
        font-size: calc(180rem/16);
    }

    @media screen and (min-width: 992px){
        font-size: calc(160rem/16);
    }
}

