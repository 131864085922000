.navbar-rolex.navbar{
  z-index: 99;
  
  height: calc(74rem/16);
    padding-left: calc(0rem/16);
    padding-right: calc(0rem/16);

  @media screen and (min-width: 768px){
     height: calc(100rem/16);
      padding-left: 5vw;
      padding-right: 5vw;
  }
}
.navbar-rolex .navbar-brand{
  padding: 0;
  margin: calc(10rem/16) calc(20rem/16) calc(8rem/16);

    width: calc(100rem/16);

    @media screen and (min-width: 768px){
        width: calc(120rem/16);
    }

  
  @media screen and (min-width: 768px){
    margin: calc(20rem/16) 0;
  }
}

.navbar-rolex .navbar-brand>img {
  width: calc(100rem/16);

    @media screen and (min-width: 768px){
        width: calc(120rem/16);
    }
}

.navbar-rolex .navbar-nav{
  @media screen and (max-width: 767px){
     background-color: #fff;
  }
}

.navbar-rolex .navbar-nav .nav-link{
  font-size: calc(12rem/16);

  @media screen and (max-width: 767px){
     display: block;
    border-top: 1px solid #e6e6e6;
  }
}

.navbar-rolex .navbar-nav .nav-link:hover,
.navbar-rolex .navbar-nav .nav-item.active .nav-link{
    color: var(--color-primary);
    height: calc(40rem/16);
}

.navbar-rolex .navbar-nav .nav-item.active{
    font-weight: 600;
}

.navbar-rolex .navbar-nav>li:not(:last-child){
  @media screen and (min-width: 768px){
    margin-right: calc(30rem/16);
  }
}

.navbar-rolex .navbar-nav>li{
  @media screen and (max-width: 767px){
    display: block;
    width: 100%;
    text-align: center;
  }
}

.navbar-rolex .navbar-nav>li:last-child a{
  @media screen and (max-width: 767px){
    border-bottom: 1px solid #e6e6e6;
  }
}

.navbar-toggler-rolex:focus{
    outline: none;
    box-shadow: none;
}

@media screen and (max-width: 767px){
   .navbar-toggler-rolex{
     background: transparent;
     border: none;
     font-size: calc(12rem/16);
     text-transform: uppercase;
     margin-right: calc(20rem/16);
   }

  .navbar-toggler-rolex__icon{
    transform: rotate(90deg);
    margin-left: calc(8rem/16);
    position: relative;
    top: calc(1rem/16);
  }

  .navbar-toggler-rolex.collapsed .navbar-toggler-rolex__icon{
    transform: rotate(-90deg);
  }

  .navbar-toggler-rolex .navbar-nav{
    background-color: #fff;
  }
}