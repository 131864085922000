.rolex-lp {
    font-size: calc(12rem / 16);
    color: var(--color-rolex-default);
}

.rolex-hr {
    background-color: #ececec;
    margin: 0;
}

.content-block-rolex, .content-block-rolex + .content-block-rolex {
    margin: calc(60rem / 16) 0;

@media screen and (min-width: 768px) {
    margin:

calc(120rem

/16) 0;
}

}

@media screen and (max-width: 767px) {
    .rolex-lp .container {
        padding: 0;
    }
}


@media screen and (min-width: 1069px) {
    .col-lg-3--rolex {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-9--rolex {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
}

.col-lg-3--rolex,
.col-lg-9--rolex {
    position: relative;
    width: 100%;
    min-height: calc(1rem / 16);
    padding-right: calc(20rem / 16);
    padding-left: calc(20rem / 16);
}

.btn-rolex-light--icon {
    height: calc(40rem /16)!important;
    width: calc(40rem /16);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}