.lg-prev:after, .lg-next:before, .lg-close:after {
    display:none;
}
.lg-close:before {
    font-family: "iconfont";
    content: var(--icon-close);
    color: #fff;
}
.lg-toolbar, .lg-sub-html {
    opacity: 0.5;

    @media screen and (max-width: 1399px){
        background-color: var(--color-primary-dark);
        opacity: 1;
    }
}

.lg-actions .lg-next, .lg-actions .lg-prev {
    background: var(--color-primary-dark);
    color: #fff;
    padding: calc(15rem/16);
    border-radius: 0;
}

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    background: var(--color-primary);
    cursor: pointer;
}

.lg-actions .lg-prev {
    left: calc(5rem/16);

    @media screen and (min-width: 1400px){
        left: 0;
    }
}

.lg-actions .lg-next {
    right: calc(5rem/16);

    @media screen and (min-width: 1400px){
        right: 0;
    }
}