.teaser-shadow{
  box-shadow: 0 calc(3rem/16) calc(10rem/16) 0 rgba(0,0,0,0.15);
  transition: all 250ms ease;
}

.teaser-shadow:hover{
  @media screen and (min-width: 992px){
    box-shadow: 0 7px 18px rgba(0,0,0,0.15);
    transform: translate(0,-3px);
  }
}