.accordion .card {
    border: none;
    border-radius: 0;
}

.accordion .card:not(:last-child) {
    border-bottom: calc(1rem/16) solid rgba(0,0,0,.125);
}

.accordion .card-header {
    background: none;
    padding: 0;
}

.accordion .card-body {
    padding-top: 0;
}

.accordion .btn:not(.btn-accordion-inline) {
    width: 100%;
    text-align: left;
    white-space: normal;
    height: auto;
    padding: calc(20rem/16) calc(65rem/16) calc(20rem/16) 0;
}

.accordion .btn-link {
    color: var(--color-text-default);
    font-weight: bold;
}

.accordion .btn-link:focus, .accordion .btn-link:hover {
    text-decoration: none;
    color: var(--color-primary);
}

.accordion .card-header, .accordion .card-body {
    padding-left: 0;
}

.accordion .card-header {
    border-bottom: 0;
}

.accordion button .accordion__close{
    position: absolute;
    right: calc(5rem/16);
    top: calc(23rem/16);
    width: calc(15rem/16);
    color: var(--color-primary);

    @media screen and (min-width: 768px){
        top: calc(25rem/16);
        width: calc(45rem/16);
    }
}

.accordion button.collapsed .accordion__close .icon{
    transform: rotate(45deg);
}

.accordion button .accordion__close .icon{
    transition: transform 150ms ease;
    transform: rotate(0deg);
}

