.table-shadow {
    box-shadow: 0 calc(5rem/16) calc(10rem/16) 0 rgba(0,0,0,0.1);
    border-collapse: separate;
}

.table table {
    margin-bottom: 0;
}

.table thead tr th {
    background-color: var(--color-main);
    color: var(--color-text-default);
    padding: calc(10rem/16);
    border-top: 0;
    line-height: calc(16/14);

    @media screen and (min-width: 768px){
        padding: calc(25rem/16) calc(25rem/16) calc(25rem/16) calc(30rem/16);
        line-height: calc(20/14);
    }
}

.table thead th {
    border-bottom: calc(1rem/16) solid #ebebeb;
}

.table thead tr th:not(:last-child), .table tbody tr td:not(:last-child) {
    border-right: calc(1rem/16) solid #ebebeb;
}

.table tr td {
    padding: calc(10rem/16);
    line-height: calc(20/14);

    @media screen and (min-width: 768px){
        padding: calc(20rem/16) calc(20rem/16) calc(20rem/16) calc(25rem/16);
        line-height: calc(30/14);
    }
}

.table td, .table th {
    border-top: calc(1rem/16) solid #ebebeb;
}