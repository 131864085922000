.slick-titles{
  @media screen and (min-width: 768px){
    max-width: calc(1250rem/16);
    margin: 0 auto;
  }
}


.slick-titles>li.slick-title-active button>span,
.slick-titles>li.slick-title-active a>span,
.slick-titles>li button:focus>span,
.slick-titles>li a:focus>span,
.slick-titles>li button:hover>span,
.slick-titles>li a:hover>span{
  color: var(--color-primary);
}


.slick-titles>li{
  display: flex;
  flex-grow: 1;
  text-align: center;
}

.slick-titles>.list-inline-item:not(:last-child){
  margin: 0;
}

.slick-titles>li button,
.slick-titles>li a{
  cursor: pointer;
}

.slick-titles>li button:focus,
.slick-titles>li a:focus{
  outline: none;
  box-shadow: none;
}


.slick-titles>li button>span,
.slick-titles>li a>span{
  position: relative;
  padding-bottom: calc(5rem/16);
}

.slick-titles>li button>span:before,
.slick-titles>li a>span:before {
  content: '';
  position: absolute;
  left: 0;
  right: calc(-2rem/16);
  bottom: 0;
  height: calc(2rem/16);
  background-color: var(--color-primary);
  transform: scaleX(0);
  transition: transform .25s ease;
  transform-origin: center;
}

.slick-titles>li.slick-title-active button>span:before,
.slick-titles>li.slick-title-active a>span:before,
.slick-titles>li button:hover>span:before,
.slick-titles>li a:hover>span:before{
  transform: scaleX(1);
}
