.map-slide{
  position: relative;
}


.interactive-map{
  position: relative;
  background-color: var(--color-light-grey);

  @media screen and (max-width: 767px){
    width: 100vw;
    min-height: calc(100vh - calc(44rem/16));
    height: 100%;
  }
}
.interactive-map:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*background: radial-gradient(ellipse at center, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 28%,rgba(255,255,255,0.7) 82%,rgba(255,255,255,0.8) 100%);*/
  /*
  background: radial-gradient(ellipse at center, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 31%,rgba(255,255,255,0.7) 88%,rgba(255,255,255,0.8) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
  */

  pointer-events: none;
}

.interactive-map__canvas{
  @media screen and (max-width: 767px){
     height: 100%;
    width: 100vw;
    min-height: calc(100vh - 2.75rem);
  }
}

.map-overlay{
  position: fixed;
  top: 0;
  z-index: 101;
  transform: translateX(100%);
  transition: all 300ms ease;
}

.map-overlay.is-open{
  transform: translateX(0);
}

.interactive-map__content {
  width: 100%;
  max-width: calc(520rem/16);
  box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5), 0 0 50px 0 rgba(0,0,0,0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(104%);
  transition: transform 300ms ease;
  z-index: 102;
  background-color: #fff;

  @media screen and (min-width: 1300px){
    max-width: calc(720rem/16);
  }
  
  @media screen and (max-width: 767px){
    overflow-y: auto;
    padding: calc(50rem/16) calc(20rem/16) 0;
    box-shadow: none;
  }
}

.detail-is-open .interactive-map__content{
  transform: translateX(0);
}

.interactive-map__content__body{
  background-color: #fff;
  padding: calc(20rem/16);
  overflow-y: auto;


  @media screen and (min-width: 768px){
    padding: calc(40rem/16);
  }

  @media screen and (min-width: 1300px){
    padding: calc(50rem/16) calc(60rem/16);
  }
}

.interactive-map__content__body .btn{
  @media screen and (min-width: 768px) and (max-width: 1300px){
    font-size: calc(13rem/16);
    line-height: calc(27rem/16);
    padding: calc(10rem/16) calc(20rem/16);
  }
}

.interactive-map__hl{
  margin-bottom: calc(20rem/16);
  
  @media screen and (min-width: 768px){
     font-size: calc(40rem/16);
  }
  
  @media screen and (max-width: 767px){
    font-size: calc(28rem/16);
    margin-bottom: calc(10rem/16);
  }
}

.interactive-map__content__bottom{
  padding: calc(5rem/16) calc(60rem/16);
  background-color: var(--color-light-grey);
  color: var(--color-main-dark);
  
  @media screen and (max-width: 767px){
    margin: 0 calc(-20rem/16);
    margin-top: calc(15rem/16);
  }
}

.interactive-map__content__close{
  background-color: var(--color-main);
  border: none;
  border-radius: 50%;
  display: inline-block;
  width: calc(35rem/16);
  height: calc(35rem/16);
  position: absolute;
  top: calc(-8rem/16);
  color: #fff;
  cursor: pointer;
  
  @media screen and (min-width: 768px){
    width: calc(60rem/16);
    height: calc(60rem/16);
    top: 50%;
    left: calc(-30rem/16);
    transform: translateY(-50%);
  }

  @media screen and (max-width: 767px){
    top: 5px;
    right: 20px;
  }
}

.interactive-map__content__close__inner{
  background-color: var(--color-main-dark);
  width: calc(25rem/16);
  height: calc(25rem/16);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  
  @media screen and (min-width: 768px){
    width: calc(46rem/16);
    height: calc(46rem/16);
  }
  
  @media screen and (max-width: 767px){
    font-size: calc(12rem/16);
  }
}

.interactive-map__back-link{
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.interactive-map__back-link .icon{
  vertical-align: calc(-4rem/16);
  margin-right: calc(3rem/16);
}

.interactive-map__heading{
  position: absolute;
  top: calc(90rem/16);
  
  @media screen and (max-width: 767px){
    padding: 0 calc(30rem/16);
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    max-width: calc(320rem/16);
    z-index: 2;
  }
  
  @media screen and (min-width: 768px){
    top: calc(20rem/16);
  }
  @media screen and (min-width: 992px){
    top: calc(90rem/16);

  }
}

.interactive-map__form {
  max-width: calc(700rem/16);
  width: 100%;
}
.interactive-map__heading .btn{
  @media screen and (max-width: 767px){
    font-size: calc(14rem/16);
  }
}


.interactive-map__heading .btn{
  @media screen and (max-width: 767px){
    display: block;
    width: 100%;
  }

}

.static-map:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 1;
  opacity: 0.6;
}

.interactive-map__btn-list>li{
  margin-top: calc(15rem/16);
}
