.navbar{
  height: calc(49rem/16);
  z-index: 100;

  @media screen and (min-width: 768px){
    height: calc(86rem/16);
  }
}

.navbar.is-affix{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.navbar-main{
  background: #fff;
  position: relative;
  z-index: 150;
  box-shadow: 0 calc(3rem/16) calc(10rem/16) 0 rgba(0,0,0,0.15);
  
  @media screen and (min-width: 768px){
     height: 86px;
  }
}

.navbar-brand{
  @media screen and (max-width: 767px){
    width: calc(80rem/16);
    margin-right: calc(8rem/16);
  }
}

.navbar-brand>img{
  width: calc(90rem/16);
  @media screen and (min-width: 1100px){
     width: calc(158rem/16);
  }

  @media screen and (min-width: 768px){
    width: calc(148rem/16);
  }
}
.navbar-right{
  @media screen and (min-width: 768px){
      height: 100%;
  }
}

.navbar__right .nav-item--img img{
  height: calc(46rem/16);
}

.navbar__right .nav-link{
  padding: calc(12rem/16);

  position: relative;
  @media screen and (min-width: 768px){
    height: calc(86rem/16);
    padding: calc(4rem/16) calc(20rem/16);

  }
}

.navbar__right>li>a {
  @media screen and (min-width: 768px) and (max-width: 1100px){
     font-size: calc(15rem/16);
  }
}

.navbar__right .nav-link:hover,
.navbar__right .nav-link:focus,
.navbar__right .nav-link:active{
  @media screen and (min-width: 768px){
    background-color: #f9f9f9;
    box-shadow: none;
  }
}

.navbar__right .nav-item:not(.nav-item--lang) .nav-link:before {
  @media screen and (min-width: 768px){
    content: '';
    position: absolute;
    right: 0;
    height: calc(38rem/16);
    width: calc(1rem/16);
    background-color: #f6f3ed;
    top: 50%;
    transform: translateY(-50%);
  }
}

.navbar__right .nav-dropdown-menu{
  @media screen and (min-width: 768px){
    width: calc(350rem/16);
    border-radius: 0;
    left: calc(-130rem/16);
    margin: calc(-1rem/16) 0 0 0;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.5);
    padding: 0;
  }

}

.navbar__right .nav-dropdown-menu__body{
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.navbar__right .nav-dropdown-menu:before {
  @media screen and (min-width: 768px){
    content: '';
    position: absolute;
    width: calc(30rem/16);
    height: calc(30rem/16);
    box-shadow: 0 0 3px 0 rgba(0,0,0,.2);
    background-color: #fff;
    top: calc(-15rem/16);
    left: calc(147rem/16);
    transform: rotate(45deg);
  }

}

.nav-dropdown-menu__item{
  padding: calc(30rem/16);
  
  @media screen and (min-width: 768px){
    padding: calc(40rem/16);
  }
}

.nav-dropdown-menu__item + .nav-dropdown-menu__item{
  border-top: 1px solid var(--color-light-grey);
}

.nav-search .control-label{
  left: 20px;
}

@media screen and (max-width: 767px){
  .dropdown--nav{
    position: static;
  }

  .dropdown--nav .nav-dropdown-menu{
    position: absolute;
    width: calc(100% - calc(40rem/16));
    border-radius: 0;
    left: 50%;
    transform: translateX(-50%);
    top: calc(59rem/16);
    border: none;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,.2);
  }

  .dropdown--nav .dropdown-toggle:before{
    content: "";
    position: absolute;
    top: calc(100% + 1px);
    left: 50%;
    display: none;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid var(--color-primary-dark);
    transform: translateX(-50%);
  }

  .dropdown--nav.show .dropdown-toggle:before{
    display: block;
  }
}

.nav-item__icon{
  font-size: calc(21rem/16);

  @media screen and (min-width: 1100px){
    font-size: calc(25rem/16);
  }

  @media screen and (min-width: 768px){
    font-size: calc(20rem/16);
  }
}

.nav-item__icon-desc{
  margin-left: calc(10rem/16);
}

.nav-item--lang .icon{
  font-size: calc(5rem/16);
  margin-left: calc(3rem/16);
}

.nav-item--lang .btn{
  background: none;
  border: none;
}

.navbar-expand-md .navbar-nav .nav-item--lang .dropdown-menu{
  @media screen and (min-width: 768px){
    min-width: 100%;
  }
}

.nav-item--lang .dropdown-toggle:after{
  border-top-color: var(--color-primary-dark);
}

.nav-item__menu{
  @media screen and (max-width: 767px){
      margin-left: calc(5rem/16);
  }
}

.navbar-toggle-text{
  font-size: calc(11rem/16);
  line-height: calc(14/13);
  text-transform: uppercase;

  @media screen and (min-width: 768px){
    font-size: calc(13rem/16);
    margin-bottom: calc(5rem/16);
  }
  
  @media screen and (max-width: 767px){
    margin-top: 3px;
    margin-bottom: 1px;
  }
}


.navbar-toggler {
  display: inline-block;
  position: relative;
  width: calc(52rem/16);
  height: auto;
  font-size: 0;
  padding: calc(4rem/16) 0;

  @media screen and (min-width: 768px){
    width: calc(83rem/16);
    padding: calc(4rem/16) calc(20rem/16);
  }
}

.navbar-toggler:focus{
  box-shadow: none;
}

.navbar-toggler:focus .navbar-toggler__bar{
  background: #000;
}

.navbar-toggler__bar {
  display: inline-block;
  width: calc(35rem/16);
  height: calc(2rem/16);
  background: var(--color-primary-dark);
  transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;

  @media screen and (min-width: 768px){
    width: calc(40rem/16);
  }
}

.navbar-toggler__bar + .navbar-toggler__bar {
  margin-top: calc(5rem/16);

  @media screen and (min-width: 768px){
    margin-top: calc(6rem/16);
  }
}


.navbar-toggler.active .navbar-toggler__bar:nth-child(2) {
  opacity: 0;
}
.navbar-toggler.active .navbar-toggler__bar:nth-child(1) {
  transform: rotate(-45deg) translateX(-6px) translateY(5px);
  width: 41px;
}
.navbar-toggler.active .navbar-toggler__bar:nth-child(3) {
  transform: rotate(45deg) translateX(-5px) translateY(-5px);
}

@media screen and (max-width: 767px){
  .navbar-toggler.active .navbar-toggler__bar:nth-child(1) {
    transform: rotate(-45deg) translateX(-5px) translateY(5px);
    width: 30px;
  }
  .navbar-toggler.active .navbar-toggler__bar:nth-child(3) {
    transform: rotate(45deg) translateX(-5px) translateY(-5px);
    width: 30px;
  }
}

.dropdown-toggle.nav-dropdown-toggle:after{
  display: none;
}

.dropdownMobile{
  font-size: 16px;
}

.dropdownMobile-select{
    display: inline-block;
}

.dropdownMobile-select.form-group select.form-control{
  height: 25px !important;
  margin-bottom: 5px;
}

.dropdownMobile label {
    display: none;
}

.dropdownMobile select.form-control {
    color: #fff;
    background: none;
    padding: 0 calc(30rem/16) 0 calc(5rem/16);
    box-shadow: none;
}

.dropdownMobile select.form-control option {
    color: var(--color-text-default);
}

.dropdownMobile .select:before {
    color: var(--color-main-dark);
    transform: translateY(-55%);
}

.navbar .rolex-retailer-clock{
  @media screen and (min-width: 768px){
     height: calc(70rem/16);
    margin-top: calc(8rem/16);
  }
}