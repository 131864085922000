@font-face {
    font-family: 'TenorSans-Regular';
    src: url('/static/fonts/TenorSans-Regular.woff2') format('woff2'),
    url('/static/fonts/TenorSans-Regular.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: 'WorkSans-Regular';
    src: url('/static/fonts/WorkSans-Regular.woff2') format('woff2'),
    url('/static/fonts/WorkSans-Regular.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: 'WorkSans-Light';
    src: url('/static/fonts/WorkSans-Light.woff2') format('woff2'),
    url('/static/fonts/WorkSans-Light.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: "HelveticaNeue-Regular";
    src: url('/static/fonts/HelveticaNeueRoman.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: "HelveticaNeue-Light";
    src: url('/static/fonts/HelveticaNeueLight.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: "HelveticaNeue-Bold";
    src: url('/static/fonts/HelveticaNeueBold.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: "Georgia";
    src: url('/static/fonts/georgia.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
}