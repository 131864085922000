.content-image-slider__headline {
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(40rem/16);
    }
}

.content-image-slider .slide__big-headline{
    left: 10px;
    
    @media screen and (max-width: 767px){
        transform: translateY(-64%);
    }
}

.content-image-slider__preheadline, .content-image-slider__headline {
    text-align: center;
}

.content-image-slider__body{
    margin-top: 20px;
    @media screen and (min-width: 768px){
       margin-top: 50px;
    }
}
.content-image-slider__wysiwyg {
    text-align: center;
    margin-bottom: calc(30rem/16);
}

.content-image-slider__slider {
    position: relative;
    height: calc(200rem/16);
    z-index: 1;
    margin-bottom: calc(30rem/16);

    @media screen and (min-width: 992px){
        height: calc(860rem/16);
        margin-bottom: 0;
    }

    @media screen and (min-width: 768px){
        height: calc(580rem/16);
        margin-bottom: 0;
    }
}

.content-image-slider__slider__inner {
    position: absolute;
    line-height: 0;
    margin-top: calc(20rem / 16);
    right: calc((20rem / 16) * -1);
    width: calc(280rem / 16);
    max-height: calc(135rem / 16);

    @media screen and (min-width: 992px){
        margin-top: calc(120rem / 16);
        right: calc((150rem / 16) * -1);
        width: calc(1080rem / 16);
        max-height: calc(640rem / 16);
    }

    @media screen and (min-width: 768px){
        margin-top: calc(80rem / 16);
        right: calc((100rem / 16) * -1);
        width: calc(730rem / 16);
        max-height: calc(600rem / 16);
    }
}


.content-image-slider__big-headline {
    margin-bottom: calc((60rem/16)*-1);
    z-index: 2;
    position: relative;

    @media screen and (min-width: 1200px){
        margin-bottom: calc((105rem/16)*-1);
    }

    @media screen and (min-width: 992px){
        margin-bottom: calc((80rem/16)*-1);
    }
}

.hide-slick-dots ul.slick-dots {
    display: none!important;
}

.hide-arrows ul.slick-dots {
    @media screen and (min-width: 768px){
        margin-left: calc((50rem/16)*-1);
    }
}

.hide-slick-dots ul.slick-dots li, .hide-arrows button.slick-arrow {
    display: none!important;
}

.content-image-slider__slider .slick-dots{
    margin: calc(28rem/16) calc(-15rem/16) 0 calc(-15rem/16);
}

.content-image-slider__slider .slick-arrow{
    position: absolute;
    bottom: 0;
}

.content-image-slider__slider button {
    background: var(--color-primary-dark);
    border: 0;
    color: #fff;
    padding: calc(0rem/16) calc(15rem/16);
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
}

.content-image-slider__slider button:focus{
    box-shadow: none;
}

.content-image-slider__slider button:hover {
    background: var(--color-primary);
}

.content-image-slider__slider button.slick-prev {
    border-right: calc(2rem/16) solid var(--color-primary);
    height: calc(40rem/16);
}

.content-image-slider__slider button.slick-next {
    margin-left: -1px;
    height: calc(40rem/16);
    transform: translateX(50%);

}

.content-image-slider__slider button>.icon{
    vertical-align: calc(-4rem/16);
}