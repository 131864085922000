.product-slider {
    position: relative;
    z-index: 2;
}

.product-slider:before {
    content: '';
    position: absolute;
    top: calc(150rem/16);
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--color-light-grey);
    z-index: 1;
}

.product-slider + .banner {
    margin-top: 0;

    @media screen and (min-width: 768px) {
        margin-top: 0;
    }

    @media screen and (min-width: 992px) {
        margin-top: 0;
    }
}

.product-slider h2 {
    margin-bottom: calc(20rem/16);

    @media screen and (max-width: 767px) {
        text-align: center;
    }
}

.product-slider h2,
.product-slider .product-slider__slider {
    position: relative;
    z-index: 5;
}


.product-slider__slider__arrows {
    background-color: var(--color-light-grey);
    display: inline-block;
    margin-top: calc(20rem/16);
    margin-bottom: calc(20rem/16);
    width: 100%;
    text-align: right;

    @media screen and (min-width: 992px) {
        margin-top: calc(40rem/16);
        margin-bottom: calc(60rem/16);
    }
}

.product-slider__slider .teaser{
    margin-right: calc(40rem/16);
    max-width: calc(380rem/16);

    @media screen and (min-width: 768px) {
        max-width: calc(450rem/16);
        margin-right: calc(20rem/16);
    }
}

.product-slider__slider .slider-arrows .btn.slick-arrow{
    padding: calc(5rem/16) calc(15rem/16);
    height: calc(39rem/16);
    line-height: 1;
}

.product-slider__slider .slick-slide{
    margin: 0 calc(20rem/16);
}

.product-slider__slider .slick-list {
    overflow: visible;
    max-width: 100%;
}

.product-slider__slider button.slick-prev {
    right: calc(50rem/16);
    border-right: 2px solid var(--color-primary);
    height: calc(40rem/16);
}

.product-slider__slider button.slick-next {
    right: 0;
    height: calc(40rem/16);
}