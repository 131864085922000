.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
  box-shadow: none;
}

.nav-tabs.nav-tabs--primary{
  border: none;
  
  @media screen and (min-width: 768px){
    max-width: calc(1250rem/16);
    margin: 0 auto;
  }
}

.nav-tabs.nav-tabs--primary .nav-link{
  font-family: var(--font-bold);
  font-size: calc(13rem/16);
  line-height: calc(22/20);
  border: none;
  border-radius: 0;
  letter-spacing: calc(3rem/16);

  @media screen and (min-width: 768px){
    font-size: calc(18rem/16);
  }
  
  @media screen and (max-width: 767px){
    letter-spacing: calc(4rem/16);
    padding: calc(13rem/16) calc(12rem/16);
  }
}

.nav-tabs.nav-tabs--primary .nav-item.show .nav-link,
.nav-tabs.nav-tabs--primary .nav-link.active,
.nav-tabs.nav-tabs--primary .nav-link:focus,
.nav-tabs.nav-tabs--primary .nav-item .nav-link:hover{
  color: var(--color-primary);
}


.nav-tabs.nav-tabs--primary .nav-link>span{
  position: relative;
  padding-bottom: calc(5rem/16);
}

.nav-tabs.nav-tabs--primary .nav-link>span:before {
  content: '';
  position: absolute;
  left: 0;
  right: calc(-2rem/16);
  bottom: 0;
  height: calc(2rem/16);
  background-color: var(--color-primary);
  transform: scaleX(0);
  transition: transform .25s ease;
  transform-origin: center;
}

.nav-tabs.nav-tabs--primary .nav-link.active>span:before{
  transform: scaleX(1);
}

@media screen and (max-width: 767px){

  .nav-scroll-container {
    position: relative;
    margin-left: calc(-15rem/16);
    margin-right: calc(-15rem/16);
  }

  .nav-scroll-container .slick-titles {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    display: block;
    font-size: 0;
  }

  .nav-scroll-container .nav-item {
    display: inline-block;
  }

}