.rolex-headline-block{
  margin: calc(40rem/16) auto;
  text-align: center;
  max-width: calc(650rem/16);

  @media screen and (min-width: 768px){
    margin-top: calc(60rem/16);
      margin-bottom: 0;

  }
  @media screen and (max-width: 767px){
     padding: 0 calc(15rem/16);
  }
}

.rolex-headline-block__toptitle{
  text-transform: uppercase ;
  font-size: calc(15.6rem/16);
  line-height: 1;
  margin-bottom: calc(20rem/16);

  @media screen and (min-width: 768px){
    font-size: calc(18rem/16);
  }
}

.rolex-headline-block__hl{
  color: var(--color-rolex-default);
  text-transform: uppercase ;
  font-size: calc(24rem/16);
  margin-bottom: calc(10rem/16);
  position: relative;
  text-align: center;

  @media screen and (min-width: 768px){
    font-size: calc(38rem/16);
      margin-bottom: calc(20rem/16);
  }
}


.rolex-headline-block__content{
  line-height: 1.8;
  font-size: calc(16rem/16);

}