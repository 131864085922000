.row.row--gutter-width-0 {
    margin-left:0;
    margin-right:0;
}

.row--gutter-width-0 > .col,
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"] {
    padding-left:0;
    padding-right:0;
}

.row.row--gutter-width-10 {
    margin-left:-5px;
    margin-right:-5px;
}

.row--gutter-width-10 > .col,
.row--gutter-width-10 > [class^="col"],
.row--gutter-width-10 > [class*=" col"] {
    padding-left:5px;
    padding-right:5px;
}


.row.row--gutter-width-20 {
    margin-left:-10px;
    margin-right:-10px;
}

.row--gutter-width-20 > .col,
.row--gutter-width-20 > [class^="col"],
.row--gutter-width-20 > [class*=" col"] {
    padding-left:10px;
    padding-right:10px;
}


/* vertical gutter */
.row--vertical-gutter-100 {
    margin-top: calc(-100rem/16);
    clear: both;
}
.row--vertical-gutter-100 > [class^="col"],
.row--vertical-gutter-100 > [class*=" col"] {
    margin-top: calc(100rem/16);
}
.row--vertical-gutter-60 {
    margin-top: calc(-60rem/16);
    clear: both;
}
.row--vertical-gutter-60 > [class^="col"],
.row--vertical-gutter-60 > [class*=" col"] {
    margin-top: calc(60rem/16);
}

.row--vertical-gutter-40 {
    margin-top: calc(-40rem/16);
    clear: both;
}
.row--vertical-gutter-40 > [class^="col"],
.row--vertical-gutter-40 > [class*=" col"] {
    margin-top: calc(40rem/16);
}

.row--vertical-gutter-20 {
    margin-top: calc(-20rem/16);
    clear: both;
}
.row--vertical-gutter-20 > [class^="col"],
.row--vertical-gutter-20 > [class*=" col"] {
    margin-top: calc(20rem/16);
}


.row--vertical-gutter-30 {
    margin-top: calc(-30rem/16);
    clear: both;
}
.row--vertical-gutter-30 > [class^="col"],
.row--vertical-gutter-30 > [class*=" col"] {
    margin-top: calc(30rem/16);
}


.row--vertical-gutter-xs-15 {
    @media screen and (max-width: 767px){
        margin-top: calc(-15rem/16);
        clear: both;
    }
}
.row--vertical-gutter-xs-15 > [class^="col"],
.row--vertical-gutter-xs-15 > [class*=" col"] {

    @media screen and (max-width: 767px){
        margin-top: calc(15rem/16);
    }
}

.row--vertical-gutter-xs-50 {
    @media screen and (max-width: 767px){
        margin-top: calc(-50rem/16);
        clear: both;
    }
}
.row--vertical-gutter-xs-50 > [class^="col"],
.row--vertical-gutter-xs-50 > [class*=" col"] {

    @media screen and (max-width: 767px){
        margin-top: calc(50rem/16);
    }
}
