.datepicker{
  position: relative;
}

.datepicker:before {
    font-family: "iconfont";
    content: var(--icon-calendar);
    color: var(--color-main-dark);
    speak: none;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: 0;
    font-size: calc(17rem/16);
    padding: calc(20rem/16);
    align-items: center;
    display: flex;
    pointer-events: none;

    @media screen and (min-width: 768px) {
        padding: calc(18rem/16);
    }
}

.ui-datepicker {
  background: #fff;
  box-shadow: 0 0 6px rgba(0,0,0, .2);
  z-index: 1005!important; /** overwrite inline style */
    display: none;
}

@media screen and (max-width: 767px){
  .ui-datepicker{
    width: auto;
  }
}
.ui-datepicker-header {
    background: var(--color-secondary);
    color: #fff;
    padding: calc(10rem/16);
    position: relative;
    height: calc(50rem/16);
}
.ui-datepicker .ui-state-default {
    text-align: center;
    width: calc(34rem/16);
    height: calc(34rem/16);
    padding: calc(4rem/16);
}
.ui-datepicker .ui-state-hover {
    background: var(--color-secondary);
    color: #fff;
}

.ui-datepicker .ui-state-disabled{
    opacity: 0.4;
}
.ui-datepicker .ui-state-active {
    background: var(--color-main);
    color: #fff;
}
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
    top: 0;
    bottom: 0;
    height: auto;
}
.ui-datepicker-next.ui-state-hover,
.ui-datepicker-prev.ui-state-hover {
    background: transparent;
    color: #000;
}
.ui-datepicker .ui-datepicker-prev span {
    position: absolute;
    left: 10%;
    top: 33%;
}
.ui-datepicker .ui-datepicker-next span {
    position: absolute;
    right: 10%;
    top: 33%;
}
.ui-datepicker .ui-datepicker-prev span:hover, .ui-datepicker .ui-datepicker-next span:hover {
    cursor: pointer;
}

.ui-datepicker .ui-datepicker-prev span:before {
    transform: rotate(180deg);
    display: block;
}
.ui-datepicker-calendar th {
    color: var(--color-main-dark);
    text-align:center;
}
.ui-datepicker-calendar td {
    color: var(--color-dark);
    text-align:center;
}

.ui-datepicker-calendar th{
    font-family: var(--font-bold);
    position: relative;
    text-transform: uppercase;
    padding: 0 10px;
}

table.ui-datepicker-calendar {
    margin: calc(10rem/16);
}

.ui-datepicker-title {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 35%);
}