.rolex-image-text-area{
  max-width: calc(800rem/16);
  margin: 0 auto;
}

.rolex-image-text-area--big{
  max-width: calc(1120rem/16);
  margin: 0 auto;
    @media screen and (max-width: 767px){
        padding: 0 calc(15rem/16);
    }
}

.rolex-image-text-area .wysiwyg{
  font-size: calc(14.4rem/16);
  line-height: 1.8;
  margin: calc(30rem/16) 0;
  
  @media screen and (max-width: 767px){
     padding: 0 calc(15rem/16);
  }
}