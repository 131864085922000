.text-media-slide__slider .content-block{
  margin: 0;
}


.text-media-slide-item {
  padding: calc(20rem/16) 0;

  @media screen and (min-width: 1300px){
    padding: calc(50rem/16) calc(70rem/16) calc(80rem/16) calc(65rem/16);
  }
  @media screen and (min-width: 768px){
    padding: calc(50rem/16) calc(30rem/16) calc(10rem/16) calc(25rem/16);

  }
}

.text-media-slide-item__media{
  padding-left: calc(15rem/16);
  position: relative;

  @media screen and (min-width: 768px){
    padding-left: calc(44rem/16);
  }
}

.text-media-slide-item__media:before {
  content: '';
  position: absolute;
  top: calc(-15rem/16);
  bottom: calc(-15rem/16);
  left: calc(-10rem/16);
  right: calc(25rem/16);
  background-color: var(--color-light-grey);

  @media screen and (min-width: 992px){
    top: calc(-50rem/16);
    bottom: calc(-50rem/16);
    left: calc(-11rem/16);
    right: calc(65rem/16);
  }

  @media screen and (min-width: 768px){
    top: calc(-40rem/16);
    bottom: calc(-40rem/16);
    left: calc(-5rem/16);
    right: calc(45rem/16);
  }
}

.text-media-slide-item__media:after{
  content: var(--icon-schnoerkel);
  font-family: iconfont;
  color: var(--color-main-dark);
  position: absolute;
  bottom: calc(-20rem/16);
  right: calc(-20rem/16);
  font-size: calc(45rem/16);
  line-height: 1;

  @media screen and (min-width: 1400px){
    bottom: calc(-77rem/16);
    right: calc(-70rem/16);
    font-size: calc(138rem/16);
  }

  @media screen and (min-width: 768px){
    bottom: calc(-55rem/16);
    right: calc(-25rem/16);
    font-size: calc(100rem/16);
  }
}

.text-media-slide-item__prehl{
  margin-bottom: calc(5rem/16);
}

.text-media-slide__slider .text-media-slide-item__prehl{
  @media screen and (min-width: 768px) and (max-width: 1200px){
    font-size: calc(15rem/16);
  }
}
.text-media-slide-item__hl{
  @media screen and (min-width: 768px) and (max-width: 1200px){
     font-size: calc(32rem/16);
  }
}

.text-media-slide-item__video{
  pointer-events: none;
}

.text-media-slide-item__text{
  margin-top: calc(15rem/16);
  @media screen and (min-width: 768px) and (max-width: 1300px){
    font-size: calc(14rem/16);
  }

  @media screen and (max-width: 767px){
    max-height: calc(130rem/16);
    overflow: hidden;
    position: relative;
  }
}

.text-media-slide-item__text.wysiwyg:after {
  @media screen and (max-width: 767px){
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(50rem/16);
    background: linear-gradient(to bottom, rgba(255,255,255,0.1) 0%,rgba(255,255,255,0.5) 57%,rgba(255,255,255,0.65) 100%);
  }

}

.text-media-slide-item__body{
    position: relative;
}

.text-media-slide-item__vertical-text{
    font-size: calc(45rem/16);
    position: absolute;
    z-index: -1;
    transform: rotate(-90deg);
    opacity: 0.2;
    line-height: 1;
    left: calc(26rem/16);
    bottom: calc(0rem/16);
    transform-origin: left bottom;

    @media screen and (min-width: 1300px){
      bottom: calc(-40rem/16);
      font-size: calc(60rem/16);
      left: calc(25rem/16);
    }


  @media screen and (min-width: 768px) and (max-width: 1300px){
    font-size: calc(47rem/16);
    left: calc(18rem/16);
    bottom: calc(-30rem/16);

  }
}

.text-media-slide-item__body .link-primary{
  @media screen and (min-width: 768px){
      margin-top: calc(20rem/16);
  }

  @media screen and (max-width: 767px) {
    margin-top: calc(10rem/16);
    margin-bottom: calc(30rem/16);
  }

  @media screen and (min-width: 768px) and (max-width: 1300px){
    font-size: calc(14rem/16);
  }

}

.text-media-slide__slider .slick-list{
  padding-top: calc(10rem/16)!important;

  @media screen and (min-width: 768px){
    padding-top: calc(20rem/16)!important;
    padding-bottom: calc(30rem/16)!important;
  }

  @media screen and (min-width: 992px){
     padding-top: calc(40rem/16)!important;
     padding-bottom: calc(20rem/16)!important;
  }

  @media screen and (min-width: 1600px){
    padding-top: calc(80rem/16)!important;
  }
}

.text-media-slide__slider .slick-slide{
  position: relative;
}

.text-media-slide__slider .slick-slide:not(.slick-current):before {
  @media screen and (min-width: 768px){
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    opacity: 0.85;
    z-index: 1;
  }

}

.text-media-slide__slider .slick-dots{
    margin-top: calc(15rem/16);

    @media screen and (min-width: 992px){
          margin-top: calc(30rem/16);
    }
    @media screen and (min-width: 768px){
        margin-top: 0;
        margin-bottom: calc(30rem/16);
    }
}

.text-media-slide__slider .slick-arrow{
  background: transparent;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: calc(7rem/16) calc(10rem/16);
  z-index: 1;
  font-size: calc(30rem/16);
  line-height: 1;
  
  @media screen and (max-width: 767px){
    top: auto;
    bottom: calc(-38rem/16);
  }
}

.text-media-slide__slider .slick-arrow:not(.slick-disabled){
  cursor: pointer;
}

.text-media-slide__slider .slick-arrow:not(.slick-disabled):hover,
.text-media-slide__slider .slick-arrow:not(.slick-disabled):focus{
  color: var(--color-primary);
  outline: none;
  box-shadow: none;
}

.text-media-slide__slider .slick-arrow.slick-prev{
  left: calc(15rem/16);

  @media screen and (min-width: 768px){
    left: calc(30rem/16);
  }

  @media screen and (min-width: 1300px){
    left: calc(80rem/16);

  }
}

.text-media-slide__slider .slick-arrow.slick-next{
  right: calc(15rem/16);

  @media screen and (min-width: 768px){
    right: calc(30rem/16);
  }

  @media screen and (min-width: 1300px){
    right: calc(80rem/16);
  }
}


