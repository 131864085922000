
.inspiration-slide-wrapper {
    margin-top: calc(80rem / 16);

    @media screen and (min-width: 992px) {
        margin-top: calc(180rem / 16);
    }
    @media screen and (min-width: 768px) and (max-width: 1650px) {
        padding: 0 calc(80rem / 16);
    }
}

.inspiration-slide__item {
    padding: calc(20rem / 16) 0;
}

.inspiration-slide__item.inspiration-slide__item--right {
    @media screen and (max-width: 767px) {
        padding-bottom: 40px;
    }
}

.inspiration-slide__item.inspiration-slide__item--left {
    position: relative;
    @media screen and (min-width: 768px) {
        padding: calc(90rem / 16) 0 calc(90rem / 16);
    }
    @media screen and (min-width: 992px){
        padding: calc(125rem / 16) 0 calc(125rem / 16);

    }
}

.inspiration-slide__item.inspiration-slide__item--left:not(.inspiration-slide__item--vertical) {
    @media screen and (min-width: 992px) {
        padding: calc(125rem / 16) 0 calc(230rem / 16);
    }
    @media screen and (min-width: 768px) {
        padding: calc(105rem / 16) 0 calc(150rem / 16);
    }
}

.inspiration-slide__item__body {
    position: relative;
}

.inspiration-slide__item__body:before {
    @media screen and (min-width: 992px) {
        top: calc(-125rem / 16);
        bottom: calc(-125rem / 16);
    }
    @media screen and (min-width: 768px) {
        background: var(--color-light-grey);
        content: '';
        position: absolute;
        top: calc(-70rem / 16);
        bottom: calc(-70rem / 16);
        width: calc(100% - 16.66%);
    }
}


@media screen and (min-width: 768px){
    .inspiration-slide__item--left .inspiration-slide__item__body:after{
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        box-shadow: 0 0 calc(400rem/16) calc(280rem/16) rgba(255, 255, 255, 0.4);
    }

    .inspiration-slide__item--right .inspiration-slide__item__body:after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        box-shadow: 0 0 calc(400rem/16) calc(280rem/16) rgba(255, 255, 255, 0.4);
        width: auto;
    }

}



.inspiration-slide__item__img:before {
    @media screen and (max-width: 767px) {
        background: var(--color-light-grey);
        content: '';
        position: absolute;
        left: calc(-25rem / 16);
        top: calc(-25rem / 16);
        bottom: calc(-25rem / 16);
        width: 100%;
    }
}

.inspiration-slide__item--left .inspiration-slide__item__body:before {
    @media screen and (min-width: 768px) {
        left: -15%;
    }
}

.inspiration-slide__item--right .inspiration-slide__item__body:before {
    @media screen and (min-width: 768px) {
        right: -15%;
    }
}

.inspiration-slide__item--left.inspiration-slide__item--vertical .inspiration-slide__item__body:before {
    @media screen and (min-width: 768px) {
        left: -21.4%;
        width: 100%;
    }
}

.inspiration-slide__item__content {
    @media screen and (min-width: 768px) {
        position: absolute;
        bottom: 0;
        width: calc(450rem / 16);
        padding: calc(65rem / 16) 0 calc(65rem / 16) calc(45rem / 16);
        z-index: 2;
        /* box-shadow: 0 0 calc(200rem / 16) calc(105rem / 16) rgba(255, 255, 255, 0.4);*/
    } @media screen and (max-width: 767px) {
    position: relative;
    z-index: 1;
    margin-top: calc(10rem / 16);
}
}

.inspiration-slide__item--left .inspiration-slide__item__content {
    @media screen and (min-width: 768px) {
        right: calc(-320rem / 16);
        /*background: linear-gradient(to right, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 31%, rgba(255, 255, 255, 0) 100%);*/
    }
}

.inspiration-slide__item--right .inspiration-slide__item__content {
    @media screen and (min-width: 992px) {
        width: calc(450rem / 16);
        left: calc(-185rem / 16);
    }
    @media screen and (min-width: 768px) {
        position: absolute;
        bottom: 0;
        width: calc(350rem / 16);
        left: calc(-205rem / 16);
        /* background: linear-gradient(to right, rgba(255, 255, 255, 0.4) 55%, rgba(255, 255, 255, 0.4) 83%, rgba(255, 255, 255, 0.4) 84%, rgba(255, 255, 255, 0.4) 100%);*/
    }

}

.inspiration-slide__item__hl {
    position: relative;
    z-index: 1;
    @media screen and (max-width: 767px) {
        font-size: calc(35rem / 16);
        line-height: calc(39 / 35);
    }
}

.inspiration-slide__item__hl--hori{
    @media screen and (min-width: 768px){
        top: -38px;
    }
}

.inspiration-slide__item__img {
    @media screen and (max-width: 767px) {
        position: relative;
    }
}

.inspiration-slide__item__content__hl {
    @media screen and (max-width: 767px) {
        font-size: calc(28rem / 16);
        line-height: calc(31 / 28);
    }
}

.inspiration-slide__item .rotate-text {
    @media screen and (max-width: 768px) {
        left: calc(-25rem / 16);
    }
}