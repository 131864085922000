.header-slider {
    position: relative;
    z-index: 3;
    max-height: 100%;
    overflow: hidden;

    color: #fff;
    text-align: center;

    @media screen and (min-width: 768px){
        height: 70vh;
    }

    @media screen and (max-width: 767px){
        width: 100vw;
        height: 58vh;
        overflow: hidden;
    }
}

.header-slider__slider__arrows {
    position: absolute;
    left: 0;
    bottom: calc(20rem/16);
    z-index: 5;
    display: inline-block;
    width: 100%;
    text-align: center;
    @media screen and (max-width: 767px){
        bottom: 0;
    }
}

.header-slider__slider .slider-arrows .btn.slick-arrow{
    padding: calc(5rem/16) calc(15rem/16);
    height: calc(39rem/16);
    line-height: 1;
    background: none;
    color: var(--color-main-middle);
}

.header-slider__slider .slick-list {
    overflow: visible;
    max-width: 100%;
}

.header-slider__slider button.slick-prev {
    margin-right: calc(30rem/16);
    height: calc(40rem/16);
}

.header-slider__slider button.slick-next {
    margin-left: calc(30rem/16);
    height: calc(40rem/16);
}