.content-video__headline {
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(40rem/16);
    }
}

.content-video__preheadline, .content-video__headline {
    text-align: center;
}

.content-video__wysiwyg {
    text-align: center;
    margin-bottom: calc(30rem/16);
}

.content-video__video {
    position: relative;
    height: calc(200rem/16);
    z-index: 1;

    @media screen and (min-width: 992px){
        height: calc(860rem/16);
    }

    @media screen and (min-width: 768px){
        height: calc(580rem/16);
    }
}

.content-video__video .content-video__video__thumbnail {
    position: absolute;
    top: calc(20rem / 16);
    right: calc((20rem / 16) * -1);
    width: calc(280rem / 16);

    @media screen and (min-width: 992px){
        top: calc(120rem / 16);
        right: calc((150rem / 16) * -1);
        width: calc(1080rem / 16);
    }

    @media screen and (min-width: 768px){
        top: calc(80rem / 16);
        right: calc((100rem / 16) * -1);
        width: calc(730rem / 16);
    }
}

.content-video__video .content-video__video__thumbnail:before {
    @media screen and (min-width: 768px){
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(59,56,62,0.5);
        z-index: 3;
    }
}

.content-video__big-headline {
    margin-bottom: calc((80rem/16)*-1);
    z-index: 2;

    @media screen and (min-width: 992px){
        margin-bottom: calc((88rem/16)*-1);
    }
}

.content-video__video .icon-schnoerkel {
    font-size: calc(52rem/16);
    position: absolute;
    bottom: calc((5rem / 16) * -1);
    right: calc((50rem / 16) * -1);
    z-index: 4;

    @media screen and (min-width: 992px){
        font-size: calc(150rem/16);
        bottom: calc(60rem/16);
        right: calc((240rem / 16) * -1);
    }

    @media screen and (min-width: 768px){
        font-size: calc(100rem/16);
        bottom: calc(40rem/16);
        right: calc((160rem / 16) * -1);
    }
}

