.metabar {
  font-size: calc(16rem/16);
  padding: calc(5rem/16) 0;
  height: calc(40rem/16);
  z-index: 149;
  position: relative;
  min-height: auto;

  @media screen and (min-width: 768px) {
    font-size: calc(20rem/16);
    height: auto;
  }
}

.metabar .icon:before {
  vertical-align: calc((5rem/16)*-1);
  font-size: calc(20rem/16);

  @media screen and (min-width: 768px) {
    font-size: calc(24rem/16);
  }
}

.metabar .icon:last-child {
  padding-right: calc(5rem/16);
}