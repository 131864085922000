.rolex-video-text-area__container{
    max-width: calc(650rem/16);
    margin: 0 auto;
}

.rolex-video-text-area{
    margin-top: calc(80rem/16);
}

.rolex-video-text-area__title{
    margin-top: calc(20rem/16);;
    color: var(--color-rolex-default);
    text-transform: uppercase ;
    font-size: calc(24rem/16);
    margin-bottom: calc(20rem/16);
    position: relative;
    text-align: center;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);

@media screen and (min-width: 768px){
    font-size: calc(38rem/16);
}

@media screen and (min-width: 768px){
    font-size: calc(36rem/16);
    margin-bottom: calc(14rem/16);
}
}

.rolex-video-text-area__body{
  padding: calc(50rem/16) calc(40rem/16);

  display: flex;
  flex-direction: column;
  justify-content: center;


  @media screen and (min-width: 1200px){
    padding: calc(90rem/16) calc(148rem/16);
    min-height: calc(460rem/16);
  }

  @media screen and (min-width: 768px){
    padding: calc(50rem/16) calc(50rem/16);
    min-height: calc(460rem/16);
  }
}

.rolex-video-text-area__text{
  font-size: calc(12rem/16);
  line-height: 1.8;

  @media screen and (min-width: 768px){
    font-size: calc(14.4rem/16);
  }
}

.rolex-video-text-area__hl{
  font-size: calc(26rem/16);
  margin-bottom: calc(20rem/16);
  text-transform: uppercase;

  @media screen and (min-width: 768px){
    margin-bottom: calc(30rem/16);
  }
}

@media screen and (max-width: 767px){
   .rolex-video-text-area__video{
     min-height: calc(180rem/16);
   }
}

.rolex-video-text-area__content{
@media screen and (max-width: 767px){
    padding: 0 calc(20rem/16);
    }
    line-height: 1.8;
    font-size: calc(16rem/16);
}