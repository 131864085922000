.overlay-menu{
  position: fixed;
  background-color: var(--color-primary-dark);
  color: #fff;
  font-family: var(--font-light);

  @media screen and (min-width: 768px){
    top: 0;
    left: auto;
    right: 0;

    width: 700px;
    height: 100%;
    overflow: hidden;

    transform: translateX(101%);
    transition: all 300ms ease;

    padding: calc(110rem/16) 0;
  }
  
  @media screen and (min-width: 992px){
    padding: calc(140rem/16) 0;

  }
  
  @media screen and (min-width: 768px) and (max-width: 991px){
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 767px){
    display: block;
    left: 0;
    right: 0;
    top: calc(49rem/16);
    transform: translateX(100%);
    transition: transform .25s ease-in-out;
    padding: calc(80rem/16) 0 calc(100rem/16);
    height: calc(100vh - calc(49rem/16));
    padding-top: calc(50rem/16);
  }

}

.navbar.is-top .overlay-menu{
  @media screen and (min-width: 768px){
    padding: 12% 0;
  }
}

.navbar.is-affix .overlay-menu{
  padding: calc(50rem/16) 0;
  
  @media screen and (min-width: 992px){
    padding: calc(160rem/16) 0;
  }

  @media screen and (min-width: 768px){
    padding: calc(140rem/16) 0;
  }
  
  @media screen and (max-width: 767px){
     padding-top: calc(30rem/16);
  }

}

.overlay-menu.is-open{
  transform: translateX(0);
}

.overlay-menu__main{
  position: relative;
  z-index: 1;
}

.overlay-menu__main li:not(:last-child){
  margin-bottom: calc(15rem/16);

  @media screen and (min-width: 768px){
    margin-bottom: 4vh; /*calc(40rem/16);*/
  }

  @media screen and (max-height: 480px){
    margin-bottom: calc(3rem/16);
  }
}

.overlay-menu__main li>a{
  font-size: calc(18rem/16);
  position: relative;
  padding-bottom: calc(12rem/16);
  transition: all .2s ease;
  font-family: var(--font-light);

  @media screen and (min-width: 992px){
      font-size: 3vh;
  }

  @media screen and (min-width: 768px){
    font-size: calc(18rem/16);
  }

}

.overlay-menu__main li>a:before {
  @media screen and (min-width: 768px){
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(2rem/16);
    background: var(--color-main-dark);

    transform: scaleX(0);
    transition: transform .25s ease;
    transform-origin: center;
  }
}

.overlay-menu__main li.is-active>a,
.overlay-menu__main li>a:hover,
.overlay-menu__main li>a:focus{
  color: var(--color-main-dark);
  box-shadow: none;
}

.overlay-menu__main li.is-active>a:before,
.overlay-menu__main li>a:hover:before{
  @media screen and (min-width: 768px){
    transform: scaleX(1);
  }
}

.overlay-menu__bottom{
  z-index: 1;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  font-size: calc(20rem/16);

  @media screen and (min-width: 768px){
    left: 50%;
    transform: translateX(-50%);
  }
  
  @media screen and (max-width: 767px){
      padding: 0 calc(10rem/16);
    left: 0;
  }
}

.overlay-menu__bottom li>a, .overlay-menu__bottom select.form-control{
  font-size: calc(15rem/16);
  line-height: calc(22/20);
  
  @media screen and (min-width: 768px){
    font-size: calc(20rem/16);
  }
}

.overlay-menu__bottom li>a:hover,
.overlay-menu__bottom li>a:focus{
  color: var(--color-main-dark);
  box-shadow: none;
}

.overlay-menu__bottom li.list-inline-item:not(:last-child) {
  margin-right: calc(10rem/16);

  @media screen and (min-width: 768px){
    margin-right: calc(100rem/16);
  }
}

.overlay-menu__bottom .btn.btn-heart{
  background-color: #013d2a;
  
  @media screen and (max-width: 767px){
     margin: 0 calc(-10rem/16);
  }
}

.overlay-menu__bottom .btn.btn-heart:hover{
  background-color: #003122;

}

.overlay-menu__bottom .btn.btn-heart:hover .icon {
  color: var(--color-main-dark);
}

.btn.btn-close-overlay{
  position: absolute;
  width: calc(50rem/16);
  height: calc(50rem/16);
  color: var(--color-main-dark);
  right: calc(50rem/16);
  top: calc(30rem/16);
  font-size: calc(22rem/16);
  padding: calc(9rem/16);
}