.contact-form {
    margin-bottom: calc(100rem/16);
}

.contact-form__form {
    width: 100%;

    @media screen and (min-width: 768px) {
        max-width: calc(450rem/16);
    }
}

.contact-form button[type="submit"] {
    margin-top: calc(70rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(30rem/16);
    }
}

.contact-form a {
    color: var(--color-primary-dark);
}

.contact-form a:hover {
    color: var(--color-primary);
}

.contact-form textarea + .control-label {
    @media screen and (min-width: 768px) {
        top: calc(30rem/16);
    }
}

.contact-form .has-error .control-label {
    top: calc(32rem/16);
    color: var(--color-danger);
}

.contact-form__product-info {
    box-shadow: 0 calc(3rem/16) calc(10rem/16) 0 rgba(0,0,0,0.15);
}

.contact-form__product-info img {
    max-width: 100%;
}

.contact-form__product-info__control-label{
    left: calc(15rem/16);
    position: absolute;
    top: calc(10rem/16);
    font-size: calc(10rem / 16);
    color: #5F5C62;
}

.contact-form__product-info__product-name {
    font-size: calc(15rem/16);
    line-height: 1;
    padding: calc(15rem/16) calc(20rem/16) 0 calc(15rem/16) ;
    margin-top: calc(15rem/16);
    color: var(--color-text-default);
    display: block;
}

.contact-form__product-info__product-name__zusatz {
    padding-left: calc(15rem/16);
    padding-bottom: calc(15rem/16);
    display: block;
    font-size: calc(15rem/16);
}

.contact-form__product-info .has-value {
    transform: translateY(-88%) scale(0.7);
}
