.footer__social__list {
    font-size: calc(50rem/16);
    line-height: 1;
}

.footer__social .h4 {
    margin-bottom: calc(25rem/16);
}

.footer__social__list li a {
    color: var(--color-main-dark);
    transition: color 300ms ease;
}

.footer__social__list li a:hover {
    color: var(--color-main);
}

.footer__social__list li:not(:last-child) {
    margin-right: calc(18rem/16);
    
    @media screen and (min-width: 768px) {
        margin-right: calc(30rem/16);
    }
}