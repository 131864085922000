.unterkunft-slide {
    position: relative;
    background-size: cover;
    padding-top: calc(50rem/16);
    z-index: 2;

    @media screen and (min-width: 992px) {
        padding-top: calc(120rem/16);
    }

    @media screen and (min-width: 768px) {
        padding-top: calc(70rem/16);
    }
}

.unterkunft-slide:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(246,243,237,0.85);
    z-index: 1;
}

.unterkunft-slide__content:after {
    font-family: "iconfont";
    content: var(--icon-schnoerkel);
    color: var(--color-main);
    position: absolute;
    bottom: calc((35rem/16)*-1);
    right: 0;
    font-size: calc(45rem/16);
    z-index: 20;

    @media screen and (min-width: 992px) {
        right: -5vw;
    }

    @media screen and (min-width: 768px) {
        font-size: 8vw;
        bottom: -5.2vw;
    }
}

.unterkunft-slide__content {
    color: #fff;
    z-index: 3;
    background-color: var(--color-main-dark);
    box-shadow: 0 calc(3rem/16) calc(10rem/16) 0 rgba(0,0,0,0.15);
    padding: calc(20rem/16) calc(50rem/16) calc(40rem/16) calc(20rem/16);

    @media screen and (min-width: 992px) {
        padding: calc(50rem/16) calc(125rem/16) calc(60rem/16) 17%;
    }

    @media screen and (min-width: 768px) {
        padding: calc(50rem/16) calc(50rem/16) calc(50rem/16) calc(210rem/16);
    }
}

.unterkunft-slide__content .h3 {
    @media screen and (max-width: 767px) {
        margin-bottom: calc(10rem/16);
    }
}

.unterkunft-slide__content .wysiwyg {
    margin-bottom: calc(30rem/16);
}

.unterkunft-slide__big-headline {
    position: absolute;
    top: calc((28rem/16)*-1);
    left: calc((30rem/16)*-1);
    z-index: 2;

    @media screen and (min-width: 992px) {
        top: calc((130rem/16)*-1);
    }

    @media screen and (min-width: 768px) {
        top: calc((70rem/16)*-1);
        left: calc((50rem/16)*-1);
    }
}

.unterkunft-slide__img {
    /*width: calc(150rem/16);
    left: calc((30rem/16)*-1); */

    position: absolute;
    display: none;
    bottom: 0;
    z-index: 10;

    @media screen and (min-width: 992px) {
        width: 25vw;
        max-width: calc(700rem/16);
        margin-left: 8%;
    }

    @media screen and (min-width: 768px) {
        left: calc((50rem/16)*-1);
        width: calc(350rem/16);
        display: block;
    }
}

.unterkunft-slide__inner {
    margin-left: calc(30rem/16) ;
}

.unterkunft-slide .form-control:focus {
    border: 1px solid var(--color-main-dark);
}

.unterkunft-slide .select:before{
    color: var(--color-main-dark);
}