.pagination{
    @media screen and (max-width: 767px){
        display: inline-block;
    }
}
.pagination li {
    background-color: var(--color-primary-dark);
    
    @media screen and (max-width: 767px){
        margin: 0;
        display: inline-block;
        margin-top: 5px;
    }
}

.pagination li:not(:last-child) {
    margin-right: calc(1rem/16);
}

.page-link {
    background: none;
    color: #ffffff;
    border: none;
    display: inline-block;
    padding-left: calc(15rem/16);
    padding-right: calc(15rem/16);
    opacity: 0.4;
    margin-left: 0;
}

.page-link:hover {
    background-color: var(--color-primary);
    color: #ffffff;
    opacity: 0.8;
}

.page-item:first-child .page-link, .page-item:last-child .page-link {
    border-radius: 0;
    opacity: 1;
}

.page-item.disabled .page-link {
    background-color: var(--color-primary-dark);
    color: #ffffff;
    border: none;
    opacity: 0.4;
}

.pagination .icon {
    vertical-align: calc((3rem/16)*-1);
}

.page-item.active .page-link {
    background-color: var(--color-primary-dark);
    opacity: 1;
}