.navbar-main{
  background-color: #3b393e;
  color: #fff;
}

.navbar__right .nav-link:hover,
.navbar__right .nav-link:focus,
.navbar__right .nav-link:active{
  @media screen and (min-width: 768px){
    background-color: #464449;
    box-shadow: none;
  }
}

.navbar-brand{
  @media screen and (min-width: 768px) and (max-width: 1025px){
    margin-right: 0;
  }
}
.navbar-brand>img{
  @media screen and (min-width: 768px) and (max-width: 1025px){
     width: calc(110rem/16);
  }
}

.nav-item__icon-desc{
  @media screen and (min-width: 768px) and (max-width: 1025px){
    font-size: calc(13rem/16);
  }
}