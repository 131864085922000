.product-img-collection_imageTriple__large {
    z-index: 1;
}

.product-img-collection_imageTriple__small {
    margin-top: calc(20rem/16);

    @media screen and (min-width: 992px){
        margin-top: calc(235rem/16);
        position: relative;
        z-index: 3;
    }

    @media screen and (min-width: 768px){
        margin-top: calc(150rem/16);
        position: relative;
        z-index: 3;
    }
}

.product-img-collection_imageTriple__medium{
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px){
        z-index: 2;
        margin-top: calc((200rem/16)*-1);
    }
}