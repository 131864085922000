.rolex-teaser-grid {
    max-width: calc(1020rem / 16);
    margin: 0 auto;
}

.rolex-teaser {
    text-align: center;
    position: relative;
    padding-bottom: calc(30rem /16);
}

.rolex-teaser:after {
    position: absolute;
    inset: 0;
    background: #000000;
    content: "";
    opacity: 0;
    transition: opacity 0.15s ease-in;
}

.rolex-teaser:hover:after {
    opacity: 0.03;
}

.rolex-teaser__hl {
    font-size: calc(10rem / 16);
    color: var(--color-rolex-default);
    font-family: var(--font-light);
    margin-bottom: 0;
    @media screen and (min-width: 768px) {
        font-size:  calc(12rem/16);
    }
}

.rolex-teaser__title{
    font-size: calc(11rem / 16);
    text-transform: uppercase;
    color: var(--color-rolex-default);
    margin-bottom: 0;
    font-family: var(--font-bold);
    @media screen and (min-width: 768px) {
        font-size:  calc(14rem/16);
    }
}

.rolex-teaser__description{
    font-size: calc(11rem / 16);
    color: var(--color-rolex-default);
    font-family: var(--font-light);
    margin-bottom: 0;
    @media screen and (min-width: 768px) {
        font-size:  calc(12rem/16);
    }
}
