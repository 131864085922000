.select {
    position: relative;
}
.select>select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select:before {
    font-family: "iconfont";
    content: var(--icon-arrow-dropdown);
    color: var(--color-primary);
    speak: none;
    position: absolute;
    bottom: 0;
    top: 50%;
    transform: translateY(-50%);
    right: calc(15rem/16);
    font-size: calc(5rem/16);
    align-items: center;
    display: flex;
    pointer-events: none;

}

.form-group select.form-control {
    height: calc(56rem/16)!important;
}

select.form-control ~ .control-label {
    transform: translateY(-88%) scale(0.7);
}

select.form-control:not([size]):not([multiple]) {
    height: calc(50rem/16);

    @media screen and (min-width: 768px) {
        height: calc(57rem/16);
    }
}

/* sm select*/
select.form-control-sm:not([size]):not([multiple]) {
    height: calc(46rem/16);
}