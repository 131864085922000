.banner {
    position: relative;
    text-align: center;
    background-size: cover;
    padding-top: calc(30rem/16);
    padding-bottom: calc(30rem/16);

@media screen and (min-width: 768px) {
    padding: calc(60rem/16) 0;
}
}

.banner:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(177,145,101,0.8);
    z-index: 1;
}

.banner__content {
    z-index: 2;
    position: relative;
}

.banner .banner__hl {
    color: #fff;
}

.banner .banner__sl {
    color: var(--color-dark);

@media screen and (max-width: 767px){
    font-size: calc(17rem/16);
}
}


.banner .btn{
    margin-top: 10px;
}

.banner__body{
    @media screen and (max-width: 767px){
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
        width: 100%;
    }
}

.banner.banner--footer{
    @media screen and (max-width: 767px){
        padding: 0;
    }
}