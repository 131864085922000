.content-block, .content-block + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(50rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(60rem/16);
    }

    @media screen and (min-width: 992px){
        margin-top: calc(130rem/16);
    }
}

.content-block-big, .content-block-big + .content-block-big,
.pimcore_area_content + .pimcore_area_content > .content-block-big {
    margin-top: calc(70rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(160rem/16);
    }

    @media screen and (min-width: 992px){
        margin-top: calc(230rem/16);
    }
}


.content-image-slider.content-block{
    margin-top: calc(80rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(110rem/16);
    }

    @media screen and (min-width: 992px){
       margin-top: calc(200rem/16);
    }
}

@media screen and (min-width: 992px) {
    .content-heading + .content-block {
        margin-top: calc(80rem/16);
    }
}

/* clear margin between banner and footer */
.banner, .no-margin-bottom {
    margin-bottom: calc((50rem/16)*-1);

    @media screen and (min-width: 768px) {
        margin-bottom: calc((60rem/16)*-1);
    }

    @media screen and (min-width: 992px) {
        margin-bottom: calc((130rem/16)*-1);
    }
}