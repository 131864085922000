.btn {
    border: none;
    height: calc(46rem/16);
    padding: calc(10rem/16) calc(20rem/16);
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px){
        line-height: calc(25/16);
    }

    @media screen and (min-width: 992px) {
        font-size: calc(16rem/16);
        line-height: calc(32/18);
        padding: calc(10rem/16) calc(35rem/16);
    }

    @media screen and (max-width: 767px) {
        white-space: normal;
        height: auto;
    }
}


.btn-lg {
    height: calc(45rem/16);
    padding: calc(10rem/16) calc(20rem/16);
    @media screen and (min-width: 992px) {
        height: calc(56rem/16);
        font-size: calc(18rem/16);
        padding: calc(11rem/16) calc(20rem/16);
    }
}

.btn__icon {
    vertical-align: calc((4rem/16)*-1);
    font-size: calc(19rem/16);
}

.btn-sm {
    height: calc(36rem/16);
    padding-top: calc(5rem/16);
    padding-bottom: calc(5rem/16);
}

.btn-primary {
    background-color: var(--color-primary-dark);
    color: #ffffff;
}

.btn-primary:focus,
.btn-primary:hover{
    background-color: var(--color-primary) !important;
    border-color:  var(--color-primary) !important;
}

.btn-secondary {
    background-color: var(--color-primary-dark);
}

.btn-dark {
    background-color: var(--color-primary-dark);
}

.btn-main {
    background-color: var(--color-primary-dark);
    color: #ffffff;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-secondary:hover,
.btn-secondary:focus,
.btn-main:hover {
    background-color: var(--color-primary);
    border-color:  var(--color-primary);
}



.btn__icon--right {
    padding-left: calc(5rem/16);
}

.btn__icon--left {
    padding-right: calc(5rem/16);
}

.btn-list .btn + .btn{
    margin-left: calc(5rem/16);
}

.btn.slick-arrow {
    font-size: calc(25rem/16);
}

.btn.slick-arrow .icon-arrow-left:before, .btn.slick-arrow .icon-arrow-right:before {
    vertical-align: calc(2rem/16);
}

.btn.btn-no-style{
    background-color: transparent;
    border: none;
}

.btn.btn-heart {
    background-color: var(--color-primary-dark);
    display: inline-flex;
    align-items: center;
    z-index: 5;
}


.btn.btn-heart .icon {
    font-size: calc(30rem/16);
    color: var(--color-primary);
    transition: all 150ms ease-in-out;
}


.btn.btn-heart .btn__icon--left {
    margin-right: calc(15rem/16);
}

.btn.btn-heart .btn__icon--right {
    margin-left: calc(15rem/16);
}

.btn.btn-heart .btn__icon--left {
    transform: scaleX(-1);
}

.btn.btn-heart:hover {
    background-color: var(--color-primary);
}

.btn.btn-heart:hover .icon {
    color: #fff;
}

.btn-product-filter {
    margin: -10px 0 20px;
}

.btn-product-filter .icon {
    font-size: calc(7rem/16);
    vertical-align: calc(2rem/16);
    margin-left: calc(5rem/16);
    color: var(--color-main);
}