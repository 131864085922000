.form-control,
.form-control[readonly] {
    height: calc(45rem/16);
    width: 100%;
    border-radius: 0;
    border: none;
    background-color: #FFFFFF;
    font-size: calc(15rem/16);
    line-height:calc(25/15);
    padding: calc(15rem/16) calc(40rem/16) 0 calc(20rem/16);
    box-shadow: 0 calc(3rem/16) calc(10rem/16) 0 rgba(0,0,0,0.15);
    margin-top: 0;
    color: var(--color-text-default);
    
    @media screen and (min-width: 992px){
        height: calc(56rem/16);
    }
}

.form-control.form-control--sm,
.form-group select.form-control.form-control--sm{
    height: calc(42rem/16)!important; /* overwrite bootstrap important */
}

.form-control.form-control--default{
    padding: 8px 30px 8px 15px;
}

.form-group {
    position: relative;
    @media screen and (min-width: 768px) {
        margin-bottom: calc(30rem / 16);
    }
}

.form-group.custom-checkbox {
    @media screen and (min-width: 768px) {
        margin-bottom: calc(15rem / 16);
    }
}

.has-error .form-control {
    border: 2px solid var(--color-danger);
}

.form-control:focus {
    box-shadow: 0 0 0 1px var(--color-primary);
}

button[type="submit"] {
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px){
        margin-top: 0;
    }
}

textarea {
    min-height: calc(150rem/16);
}

.control-label {
    position: absolute;
    font-size: calc(15rem / 16);
    left: calc(20rem / 16);
    top: 50%;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out, color 200ms ease-in-out;
    transform-origin: left top;
    color: #5F5C62;

    @media screen and (min-width: 768px) {
        /*left: calc(40rem / 16);*/

    }
}

textarea + .control-label {
    top: calc(30rem/16);
}

textarea.form-control {
    padding-top: calc(25rem/16);
}

.form-control:focus ~ .control-label,
.select ~ .control-label,
.form-control.has-value ~ .control-label,
.form-control.has-already-value ~ .control-label {
    transform: translateY(-88%) scale(0.7);
    z-index: 3;
}


input[type="file"].form-control {
     padding: calc(13rem / 16) calc(20rem / 16);
}

input[type="file"]::-webkit-file-upload-button{
    border: 1px solid var(--color-primary);
}


/* optimize appearance in firefox */
@supports (-moz-appearance:none) {
    input[type="file"].form-control {
        padding-top: 0;
    }
}

.search-form .btn{
    @media screen and (min-width: 992px){
       height: calc(56rem/16);
    }
}

.search-form .btn{
    height: calc(45rem/16);
    @media screen and (min-width: 992px){
        height: calc(56rem/16);
    }
    
    @media screen and (max-width: 767px){
       margin-top: 0;
        margin-bottom: calc(15rem/16);
    }
}