footer {
    text-align: center;
    position: relative;
}

.footer__element {
    border-bottom: 1px solid rgba(250,250,250,0.2);
    padding: calc(48rem/16) 0;
}

.footer__credits {
    font-size: calc(16rem/16);
    padding: calc(25rem/16) 0;
}

.footer__credit__right {
    margin-top: calc(10rem/16);
}

@media screen and (min-width: 768px) {
    .footer__credit__left {
        text-align: left;
    }

    .footer__credit__right {
        text-align: right;
        margin-top: 0;
    }
}

.footer__credit__right li:not(:last-child):after {
    content: "|";
    padding-left: calc(10rem/16);
}

.footer__credit__right li a {
    transition: color 300ms ease;
}

.footer__credit__right li a:hover {
    filter: drop-shadow( 0 calc(1rem/16) calc(3rem/16) var(--color-main-dark) );
}

.footer__credit__right li a:hover img {
    filter: drop-shadow( 0 0 calc(1rem/16) var(--color-main-dark) );
}

.newsletter__form {
    margin-top: calc(15rem/16);
}