button.video-btn {
    border: 0;
}

.video-btn {
    border-radius: 50%;
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    background-color: var(--color-primary);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 6;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    pointer-events: auto;

    @media screen and (min-width: 992px) {
        width: calc(155rem / 16);
        height: calc(155rem / 16);
    }
    @media screen and (min-width: 768px) {
        width: calc(100rem / 16);
        height: calc(100rem / 16);
    }
}

.video-btn.video-btn--left {
    left: 0;

    @media screen and (min-width: 992px) {
        left: calc(-15rem / 16);
    }

    @media screen and (min-width: 768px) {
        left: calc(10rem / 16);
    }
}

.video-btn__inner {
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    font-size: calc(9rem / 16);
    line-height: calc(13rem / 16);
    font-family: var(--font-default);
    -webkit-border-radius: 50%;
    border-radius: 50%;      /* TODO test ipad*/
    width: calc(50rem / 16);
    height: calc(50rem / 16);
    background-color: var(--color-primary-dark);
    align-items: center;

    @media screen and (min-width: 992px) {
        width: calc(132rem / 16);
        height: calc(132rem / 16);
        font-size: calc(22rem / 16);
        line-height: calc(27rem / 16);
    }

    @media screen and (min-width: 768px) {
        width: calc(80rem / 16);
        height: calc(80rem / 16);
        font-size: calc(15rem / 16);
        line-height: calc(19rem / 16);
    }

    transition: background-color 0.5s ease;
}

.video-btn:hover {
    background-color: var(--color-primary-dark);
    cursor: pointer;
}

.video-btn:hover .video-btn__inner {
    background-color: var(--color-primary);
}

.vjs-default-skin .vjs-big-play-button {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: var(--color-primary-dark);
    border-color: transparent;
}

.vjs-big-play-button .vjs-icon-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    height: calc(40rem / 16);
    width: calc(50rem / 16);
    font-size: calc(50rem / 16);
}

.video-js .vjs-big-play-button:focus,
.video-js:hover .vjs-big-play-button {
    background-color: var(--color-primary);
    opacity: 0.8;
}


