.content-heading__headline {
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(40rem/16);
    }
}

.content-heading__preheadline{
    margin-bottom: calc(10rem/16);
}

.content-heading__preheadline,
.content-heading__headline {
    text-align: center;
}

.content-heading__wysiwyg {
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(50rem/16);
        text-align: center;
    }

    @media screen and (min-width: 992px){
        margin-bottom: calc(70rem/16);
    }
}

.page-heading .content-heading__wysiwyg {
    margin-bottom: 0;
}

.page-heading {
    /*margin-bottom: calc((20rem/16) * -1);*/

    @media screen and (min-width: 992px){
       /* margin-bottom: calc((60rem/16) * -1);*/
        margin-top: calc(90rem/16);
    }

    @media screen and (min-width: 768px){
       /* margin-bottom: calc((30rem/16) * -1);*/
    }
}


.content-brand-logo {
    @media screen and (min-width: 768px) {
        margin-top: calc((30rem / 16) * -1);
    }
}
