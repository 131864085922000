.content-image-and-text.content-block + .content-image-and-text.content-block {
    @media screen and (min-width: 768px){
        margin-top: 0;
    }
}

.content-image-and-text + .content-image-and-text{
    @media screen and (max-width: 767px){
       margin-top: calc(30rem/16);
    }
}

.content-image-and-text__text {
    padding: calc(20rem/16);

    @media screen and (min-width: 768px){
        padding: calc(40rem/16);
    }

    @media screen and (min-width: 992px){
        padding: 16% 24% 16% 15%;
    }
}

.brand-item--small .content-image-and-text__text{
    padding: 20px;
}

@media screen and (min-width: 768px){
    .brand-item--small .content-image-and-text__img img{
        transition: all 300ms ease;
    }

    .brand-item--small:hover .content-image-and-text__img img{
        transform: translate(-50%) scale(1.05);
    }

}

.brand-item--small .content-image-and-text__logo img{
    height: auto;
    @media screen and (min-width: 1200px){
       max-width: 250px;
    }
    
    @media screen and (min-width: 768px){
       max-width: 200px;
    }
}

.brand-item--small .content-image-and-text__link>a{
    @media screen and (min-width: 768px) and (max-width: 1200px){
       font-size: 15px;
    }
}

.content-image-and-text--img-right:not(.content-image-and-text--brand) .content-image-and-text__text {
    @media screen and (min-width: 992px){
        padding: 16% 15% 16% 24%;
    }
}

.content-image-and-text__headline {
    margin-bottom: calc(15rem/16);

    @media screen and (min-width: 992px){
        margin-bottom: calc(40rem/16);
    }
}

.content-image-small-and-text .content-image-small-and-text__hl {
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(40rem/16);
    }
}

.content-image-and-text__img ~.content-image-and-text__text {
    padding-right: calc(50rem/16);
}

.content-image-and-text__wysiwyg{
    @media screen and (min-width: 768px) and (max-width: 991px){
        font-size: calc(17rem/16);
    }
}

.content-image-and-text__img {
    width: 100%;
    height: 100%;
}


.content-image-and-text__link {
    margin-top: calc(30rem/16);
}

.two-images__img-big {
    z-index: 1;
}

.two-images__img-small {
    margin-top: calc(-50rem/16);

    @media screen and (min-width: 768px){
        z-index: 2;
        margin-top: calc((130rem/16)*-1);
    }
}