.btn.btn-rolex-default{
  background-color: var(--color-rolex-primary);
  color: #fff;
  text-transform: uppercase;
  border: 1px solid var(--color-rolex-primary);
  font-size: calc(14rem/16);
  line-height: 1;
    display: inline-flex;
    align-items:center;
    height: calc(36rem/16);
    width: fit-content;


    @media screen and (min-width: 768px){
        height: calc(40rem/16);
        padding-left: calc(30rem/16);
        padding-right: calc(30rem/16);
        font-size: calc(16rem/16);
    }
    line-height: 1;
    padding-left: calc(25rem/16);
    padding-right: calc(25rem/16);
}

.btn.btn-rolex-default:hover{
  background-color: #fff;
  color: var(--color-rolex-primary);
    border: 1px var(--color-rolex-primary) solid;
}

.btn.btn-rolex-default.btn-rolex-default--lowercase{
    text-transform: none;
    margin-top: calc(20rem/16);
}

.btn.btn-rolex-outline{
    background-color: transparent;
    color: var(--color-rolex-primary);
    border: 1px solid var(--color-rolex-primary);
    font-size: calc(16rem/16);
    height: calc(36rem/16);

    @media screen and (min-width: 768px){
        height: calc(40rem/16);
        padding-left: calc(30rem/16);
        padding-right: calc(30rem/16);
    }
    line-height: 1;
    padding-left: calc(25rem/16);
    padding-right: calc(25rem/16);
}

.btn.btn-rolex-outline:hover{
  background-color: var(--color-rolex-primary);
  color: var(--color-rolex-white);
}
.btn.btn-rolex-outline.btn-rolex-outline--white{
    background-color: transparent;
    color: white;
    border: 1px solid white;
}

.btn.btn-rolex-outline.btn-rolex-outline--white:hover{
  background-color: white;
  color: var(--color-rolex-light-black);
}

.btn-rolex-icon-primary{
    width: calc(36rem/16);
    height: calc(36rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    background-color: var(--color-rolex-white);
    color: var(--color-rolex-primary);
    @media screen and (min-width: 768px){
        width: calc(40rem/16);
        height: calc(40rem/16);
    }
}

.btn-rolex-icon-outline{
    width: calc(36rem/16);
    height: calc(36rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    color: var(--color-rolex-white);
    border: 1px solid var(--color-rolex-white);
    @media screen and (min-width: 768px){
        width: calc(40rem/16);
        height: calc(40rem/16);
    }
}

.btn-rolex-icon-outline:hover{
    background-color: #fff;
    color: var(--color-rolex-primary);
    border: 1px solid var(--color-rolex-white);
}

.btn-rolex-icon-outline-md--green{
    @media screen and (max-width: 991px){
        width: calc(36rem/16);
        height: calc(36rem/16);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        color: var(--color-rolex-primary);
        border: 1px solid var(--color-rolex-primary);
    }

}

.btn-rolex-icon-outline-md--green:hover{
    @media screen and (max-width: 991px){
        background-color: var(--color-rolex-primary);
        color: #fff;
        border: 1px solid var(--color-rolex-primary);

    }
}

.btn-rolex-icon-primary:hover{
    background-color: var(--color-rolex-primary);
    color: var(--color-rolex-white);
}