.rolex-header {
    position: relative;
}
.rolex-header__content {
   text-align: left;
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   right: 10%;
   padding: 0;
    @media screen and (min-width: 1200px){
        right: 18%;
    }
}

.rolex-header__title {
    font-family: var(--font-default);
    text-transform: uppercase;
}