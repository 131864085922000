.news-events-slide{
  position: relative;
  padding: calc(40rem/16) 0;

  @media screen and (min-width: 768px){
      padding: calc(65rem/16) 0;
  }
}

.news-events-slide:before{
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.7;
  z-index: 1;
  
  @media screen and (max-width: 767px){
     opacity: 0.75;
  }
}
.news-events-slide__hl{
    color: #fff;
}


.news-events-slide__body{
  position: relative;
  z-index: 2;
}



.news-events-slide button.slick-prev {
    right: calc(50rem/16);
    border-right: calc(2rem/16) solid var(--color-primary);
    height: calc(40rem/16);
}

.news-events-slide button.slick-next {
    right: 0;
    height: calc(40rem/16);
}

.news-events-slide .content-teaser__hl {
    text-transform: none;
    letter-spacing: normal;
}