.form-errors {
    color: var(--color-danger);
}
.form-errors:empty {
    display: none;
}

.has-error .control-label {
    transform: translateY(-80%);
}

.newsletter .has-error .control-label {
    color: var(--color-danger);
}

ul.parsley-errors-list{
    list-style: none;
    padding: 0;
    font-size: calc(12rem/16);
    position: absolute;
    color: var(--color-danger);
    line-height: calc(15rem/16);
    bottom: calc(2rem/16);
    right: calc(20rem/16);
}

.custom-checkbox .parsley-errors-list{
    left: 0;
    right: auto;
    top: 100%;
}
