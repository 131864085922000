
.header-sujet{
  position: relative;
  color: #fff;
  text-align: center;
  
  @media screen and (min-width: 768px){
     height: 70vh;
  }

  @media screen and (max-width: 767px){
    width: 100vw;
    height: 58vh;
    overflow: hidden;
  }
}

.header-sujet.header-sujet--with-img{
  @media screen and (min-width: 768px) and (max-width: 991px){
    width: 100vw;
    height: calc(70vh - calc(86rem/16));
    overflow: hidden;
  }
}

.header-sujet .header-sujet__video:before{
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  z-index: 2;
  opacity: 0.5;
}


.header-sujet__video{
  @media screen and (min-width: 768px){
    position: absolute;
    height: 70vh;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-position:center;
    background-color: var(--color-text-default);

  }
}

.header-sujet__video{
  animation-name: videoFadeIn;
  animation-duration: 0.7s;
  animation-delay: 0.6s;
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes videoFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.header-sujet__video video{
  position: absolute;

  height: auto;
  width: auto;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

}

.header-sujet__img {
  background-size:cover;
  background-position:center;
  width: 100%;

  @media screen and (min-width: 768px){
    height: 70vh;
  }
  @media screen and (max-width: 767px){
    height: 100%;
  }
}

.header-sujet__img--absolute {
    position: absolute;
    top: 0;
    left: 0;
}

.header-sujet__body{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;

  animation-name: fadeIn;
  animation-duration: 0.7s;
  animation-delay: 0.9s;
  opacity: 0;
  animation-fill-mode: forwards;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-70%);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
  }
}

.header-sujet__hl{
  @media screen and (max-width: 767px){
     font-size: calc(45rem/16);
  }
}
.header-sujet__prehl{
  text-transform: uppercase;
  font-size: calc(15rem/16);
  line-height: calc(18/15);
  margin: calc(10rem/16) 0;
  letter-spacing: calc(2rem/16);
  position: relative;

  @media screen and (min-width: 768px){
    font-size: calc(25rem/16);
    margin: calc(15rem/16) 0;
    line-height: calc(30/25);
  }

  @media screen and (min-width: 992px){
    font-size: calc(30rem/16);
    margin: calc(25rem/16) 0;
    line-height: calc(35/30);
  }

    transition: all;
}

.header-sujet__prehl .icon-heart-right {
    display: inline-block;
    color: var(--color-main-middle);
    font-size: calc(40rem/16);
    transition: all ease;


  @media screen and (max-width: 767px){
    padding-left: calc(5rem/16);
    transform:  scale(1.5) translateX(-69px);
    animation: transform-heart-right 1.5s ease 1s both;

  }

    @media screen and (min-width: 768px){
      font-size: calc(60rem/16);
      animation: expand-heart 1.5s ease 1s both;
      transform: scale(1.5);
    }
}


@keyframes expand-heart {
   0% {
     transform: scale(1.5);
   }

   25% {
     transform: scale(1.5);
   }

   100% {
     transform: scale(1);
   }
 }


@keyframes transform-heart-right {
  0% {
    transform: scale(1.5) translateX(-69px);
  }

  100% {
    transform: scale(1.1) translateX(0);
  }
}

.header-sujet__prehl .icon-heart-left {
    display: inline-block;
    color: var(--color-main-middle);
    transition: all ease;
    font-size: calc(40rem/16);
  transform: scale(1.5);


  @media screen and (max-width: 767px){
    padding-left: calc(5rem/16);
    transform:  scale(1.5) translateX(69px);
    animation: transform-heart-left 1.5s ease 1s both;

  }

    @media screen and (min-width: 768px){
      font-size: calc(60rem/16);
      animation: expand-heart 1.5s ease 1s both;

    }
}


@keyframes transform-heart-left {
  0% {
    transform: scale(1.5) translateX(69px);
  }

  100% {
    transform: scale(1.1) translateX(0);
  }
}

.header-sujet__prehl .text {
    display: inline-block;
    vertical-align: calc(12rem/16);

    @media screen and (min-width: 768px){
        vertical-align: calc(18rem/16);
      animation: expand 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1.5s both;

    }
  
  @media screen and (max-width: 767px){
     max-width: calc(205rem/16);
    min-height: calc(36rem/16);
    animation: expand-mobile 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1.5s both;

  }
}

@keyframes expand {
    0% {
        font-size: 0;
        opacity: 0;
        width: 0;
    } 1% {
        width: auto;
    } 30% {
        opacity: 0.4;
        width: auto;
    } 100% {
        opacity: 1;
        width: auto;
    }
}


@keyframes expand-mobile {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


.header-sujet__schnoerkel{
  font-size: calc(30rem/16);
  @media screen and (min-width: 768px){
    font-size: calc(55rem/16);
  }
  
  @media screen and (max-width: 767px){
      margin-top: calc(15rem/16);
  }
}

.header-sujet__scroll-down{
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 10;
  font-size: calc(28rem/16);
  line-height: 1;
  transform: translateX(-50%) scale(0);
  transform-origin: top center;
  animation: scrollDownAnim 1.5s ease-out infinite;
  opacity: 0;

  @media screen and (min-width: 768px){
    bottom: calc(25rem/16);
    font-size: calc(40rem/16);
  }
}

@keyframes scrollDownAnim {
  0% {
    opacity: 0;

  }
  25% {
    opacity: 1;
    transform: translateX(-50%) scale(0.2);

  }

  50% {
    opacity: 1;
    transform: translateX(-50%) scale(0.5);

  }

  75% {
    opacity: 1;
    transform: translateX(-50%) scale(0.7);

  }

  100% {
    opacity: 0;
    transform: translateX(-50%) scale(1);

  }
}


.header-sujet .btn-list .btn{
  @media screen and (min-width: 768px){
    font-size: calc(22rem/16);
    padding: calc(7rem/16) calc(20rem/16);
    min-width: calc(213rem/16);
  }
}

/* rolex header sujet */

.header-sujet__body--rolex {
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 1rem 1rem 2.5rem 1rem;
    background-color: rgba(255,255,255,0.7);
    position: absolute;
    z-index: 2;

    @media screen and (min-width: 768px){
        background: none;
        top: 50%;
        left: 70%;
        width: 33%;
        bottom: unset;
        transform: translateX(-50%) translateY(-50%);
        z-index: 2;
    }
}

.header-sujet__title--rolex {
    font-family: var(--font-default);
    text-transform: uppercase;
    color: #3B383E!important;
    @media screen and (max-width: 767px){
        margin-bottom: 0.5rem;
    }
}