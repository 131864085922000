/*Margin Helper*/

.mt10{ margin-top: calc(10rem/16); }
.mt20{ margin-top: calc(20rem/16); }
.mt25{ margin-top: calc(25rem/16); }
.mt15{ margin-top: calc(15rem/16); }
.mt30{ margin-top: calc(30rem/16); }
.mt40{ margin-top: calc(40rem/16); }

.mb20{ margin-bottom: calc(20rem/16); }
.mb30{ margin-bottom: calc(30rem/16); }
.mb40{ margin-bottom: calc(40rem/16); }

.mr30{ margin-right: calc(30rem/16); }

.ml220 {
    @media screen and (min-width: 992px){
        margin-left: calc(220rem/16);
    }
}

.special-margin{
    @media screen and (min-width: 768px){
        margin-left: 12.5%;
    }
}

/*Padding Helper*/
.pl0-md {@media screen and (max-width: 991px){ padding-left: 0!important;} }
.pl30 {padding-left: calc(30rem/16); }
.pl30-xl {@media screen and (min-width: 992px){padding-left: calc(30rem/16);} }
.pl50 {padding-left: calc(50rem/16); }
.pl50-xl {@media screen and (min-width: 992px){padding-left: calc(50rem/16);} }

/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.link-primary {
    color: var(--color-primary-dark);
    display: inline-block;
    font-family: var(--font-bold);
    position: relative;
    padding-left: calc(30rem/16);
    text-align: left;
    
    @media screen and (min-width: 768px){
       font-size: calc(18rem/16);
    }

    @media screen and (min-width: 992px){
        font-size: calc(20rem/16);
    }
}
.link-primary:before {
    font-family: "iconfont";
    content: var(--icon-arrow-right);
    margin-right: calc(10rem/16);
    display: inline-block;
    vertical-align: calc((3rem/16)*-1);
    transition: transform 200ms ease-out;
    position: absolute;
    left: 0;
}

.link-primary:hover:before {
    transform: translateX(calc(3rem/16));
}

.isClickable:hover {
    cursor: pointer;
}


/*Text Color Helper*/
.text-primary { color: var(--color-primary) !important; }
.text-primary-dark { color: var(--color-primary-dark); }
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-main { color: var(--color-main); }
.text-main-dark { color: var(--color-main-dark); }
.text-main-light { color: #D7CDB8; }
.text-main-middle { color: var(--color-main-middle); }


/*Background Color Helper*/
.bg-primary { color: #fff;}
.bg-primary-dark {background: var(--color-primary-dark); color: #fff;}
.bg-secondary {background: var(--color-secondary); color: #fff;}
.bg-grey { background: var(--color-grey); color: var(--color-text-default);}
.bg-light-grey { background: var(--color-light-grey); color: var(--color-text-default);}
.bg-main {background: var(--color-main); color: #fff;}
.bg-main-dark {background: var(--color-main-dark); color: #fff;}
.bg-dark {background: var(--color-dark) !important; color: #fff;}



/*Font Helper*/
.font-default{ font-family: var(--font-default); }
.font-light{ font-family: var(--font-default); }
.font-bold{ font-family: var(--font-bold); }
.font-regular{ font-family: var(--font-regular); }


.bg-img{
    background-size: cover;
    background-repeat: no-repeat;
}

.rotate-text{
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    transform-origin: left bottom;
    transform: rotate(-90deg) translateY(50%);
    margin: 0;
}

.toc .sr-only-focusable:active,
.toc .sr-only-focusable:focus{
    position: absolute;
}

.slide__big-headline {
    position: absolute;
    transform: translateY(-54%);
    top: 0;
    left: calc((7rem/16)*-1);
    z-index: 2;

    @media screen and (min-width: 992px) {
        left: calc((26rem/16)*-1);
    }

    @media screen and (min-width: 768px) {
        left: calc((13rem/16)*-1);
    }
}


.whatsapp-btn{
    display: none;
    @media screen and (max-width: 767px){
       width: calc(35rem/16);
        display: block;
        position: fixed;
        bottom: calc(20rem/16);
        right: calc(20rem/16);
        z-index: 10;
    }
}

.whatsapp-btn a{
    display: block;
}