.teaser-slider .teaser{
    max-width: calc(330rem/16);
}

.teaser-slider .slider-arrows{
    text-align: right;
}
.teaser-slider .slider-arrows .btn.slick-arrow{
    padding: calc(5rem/16) calc(15rem/16);
    height: calc(39rem/16);
    line-height: 1;
}

.teaser-slider .slick-slide{
    margin: 0 calc(20rem/16);
}

.teaser-slider .slick-list {
    overflow: visible;
    max-width: 100%;
}
