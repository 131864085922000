.image-row a:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, rgba(59,56,62,0.9) 0%, rgba(59,56,62,0) 50%);
    z-index: 1;
    opacity: 0;

    -webkit-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
}

.image-row a:hover:after {
    opacity: 0.5;
}

