html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}

body.nav-open:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    opacity: 0.75;
    z-index: 100;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.container.container {
    max-width: calc(1470rem/16); /* container 1440px + 30px padding */
    width: 100%;
    padding-right: calc(20rem/16);
    padding-left: calc(20rem/16);

    @media screen and (min-width: 768px){
        max-width: calc(1480rem/16); /* container 1440px + 40px padding  */
        width: 100%;
        padding-right: calc(20rem/16);
        padding-left: calc(20rem/16);
    }
}

.container .container{ /* Fix because of wrong cms implementation */
    padding: 0;
}


.container-rolex, .container-rolex.container-rolex {
    margin: 0 auto;
    max-width: calc(1180rem/16);
    width: 100%;
    padding-right: calc(20rem/16);
    padding-left: calc(20rem/16);
    margin: 0 auto;

    @media screen and (min-width: 768px){
        max-width: calc(1180rem/16); /* container 1440px + 40px padding  */
        width: 100%;
        padding-right: calc(20rem/16);
        padding-left: calc(20rem/16);
    }
}

.container-rolex--small, .container-rolex--small.container-rolex--small {
    margin: 0 auto;
    max-width: calc(690rem/16);
    width: 100%;
    padding-right: calc(20rem/16);
    padding-left: calc(20rem/16);

    @media screen and (min-width: 768px){
        max-width: calc(690rem/16);
        width: 100%;
        padding-right: calc(20rem/16);
        padding-left: calc(20rem/16);
    }
}

.container .container{ /* Fix because of wrong cms implementation */
    padding: 0;
}

/*.container.container-slider{
    @media screen and (min-width: 768px){
        max-width: calc(1615rem/16); // container 1440px + 40px padding
        width: 100%;
        padding-right: calc(20rem/16);
        padding-left: calc(20rem/16);
    }
}*/

.container-outer {
  /*max-width: calc(1920rem/16);*/
  margin: 0 auto;
  overflow: hidden;
}
