.rolex-teaser-slider{
    //padding-top: calc(70rem/16);
  max-width: calc(1240rem/16);
  margin: 0 auto;
}

.rolex-teaser-slider__slider{
  margin: 0;

  @media screen and (min-width: 768px){
    margin: 0;
  }
}


.rolex-teaser-slider-item{
  position: relative;
}

.rolex-teaser-slider-item:hover img{
    overflow: hidden;
    transform: scale(1.05);
}


.rolex-teaser-slider-item img{
    transition: transform 0.3s ease-out;
    transform: scale(1);
}


.rolex-teaser-slider-item__hl{
  text-transform: uppercase;
    margin-top: calc(10rem/16);
    margin-bottom: 0;
    font-size: calc(16rem/16);

  @media screen and (min-width: 768px){
      font-size: calc(18rem/16);
  }
}

.rolex-teaser-slider-item__hl.rolex-teaser-slider-item__hl--small{
    margin-top: 0;
    margin-bottom: 0;
    font-size: calc(11rem/16);

  @media screen and (min-width: 768px){
      font-size: calc(14rem/16);
  }
}




.rolex-teaser-slider-item__hl:hover{
  color: var(--color-rolex-default);
}

.rolex-teaser-slider-item{
  margin-right: 1px;
}

.rolex-teaser-slider-row{
  display: flex!important;
}

.rolex-teaser-slider__slider .slick-dots{
    margin-top: calc(30rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(35rem/16);
    }
    @media screen and (min-width: 992px){
        margin-top: calc(40rem/16);
    }
}

.rolex-teaser-slider__slider .slick-dots>li{
    width: calc(70rem/16);
    height: calc(6rem/16);
    position: relative;
    margin: 0 calc(4rem/16);
}

.rolex-teaser-slider__slider .slick-dots>li button{
     width: calc(70rem/16);
     height: calc(4rem/16);
     background-color: var(--color-rolex-default);
     opacity: 0.2;
     border-radius: calc(5rem/16);
}

.rolex-teaser-slider__slider .slick-dots>li.slick-active button{
    background-color: var(--color-primary-dark);
    opacity: 1;
}

@media screen and (max-width: 767px){
    .rolex-teaser-slider__slider .slick-dots>li button {
        width: calc(40rem / 16);
        height: calc(4rem / 16);
    }
    .rolex-teaser-slider__slider .slick-dots>li{
        width: calc(40rem / 16);
        height: calc(4rem / 16);
    }
}

.rolex-teaser-slider .slick-next{
    position: absolute;
    top: 40%;
    right: -5%;
    cursor: pointer;
    color: var(--color-rolex-primary);


    	@media screen and (min-width: 768px) and (max-width: 992px){
            right: calc(-12rem/16);
            border-radius: 50%;
            width: calc(40rem/16);
            height: calc(40rem/16);
            background-color: rgba(255,255,255,0.9);
    	}
}

.rolex-teaser-slider .slick-prev{
    position: absolute;
    top: 40%;
    left: -5%;
    cursor: pointer;
    transform: rotate(180deg);
    color: var(--color-rolex-primary);

    @media screen and (min-width: 768px) and (max-width: 992px){
        left: calc(-12rem/16);
        border-radius: 50%;
        width: calc(40rem/16);
        height: calc(40rem/16);
        background-color: rgba(255,255,255,0.9);
        z-index:500;
    }
}

.rolex-teaser-slider .icon{
    display: flex;
    justify-content: center;
}




.rolex-teaser-slider .slick-arrow{
    background-color: transparent;
    border: none;
}

.rolex-teaser-slider .slick-arrow.slick-disabled{
   opacity: 0;
    pointer-events: none;
}

.rolex-teaser-slider__title{
    margin-bottom: calc(30rem/16);
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px){
        margin-left: calc(5rem/16);
        margin-bottom: calc(40rem/16);
    }
}

.rolex-teaser-slider__title.rolex-teaser-slider__title--recommendation{
    font-size: calc(26rem/16);
    margin-bottom: calc(30rem/16);
    text-transform: uppercase;
    color: #111111;
    text-align: center;

    @media screen and (min-width: 768px){
        margin-bottom: calc(20rem/16);
    }
}


