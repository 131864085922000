.brands-row{
    padding: calc(10rem/16) 0;

    @media screen and (min-width: 768px){
        padding: calc(30rem/16) 0;
    }
}

.brands-row .accordion{
    position: relative;
}

.brands-row .accordion:after{
    content: '';
    position: absolute;
    height: calc(2rem/16);
    width: calc(150rem/16);
    background-color: var(--color-primary);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.brands-row .accordion .accordion__close{
    top: auto;
    right: auto;
    bottom: -13px;
    left: 50%;
    transform: translateX(-50%);
    background: #b19165;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    z-index: 1;
}

.brands-row .accordion .btn:not(.btn-accordion-inline){
    display: inline-block;
    width: auto;
    margin: 0 auto;
    padding: calc(15rem/16) calc(10rem/16);
    position: relative;
    z-index: 5;
    @media screen and (min-width: 768px){
        padding: calc(20rem/16) calc(65rem/16);
    }
}