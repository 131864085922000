.product-teaser{
  background-color: #fff;
  height: 100%;
  z-index: 10;
}

.product-teaser__hl {
  text-transform: none;
  letter-spacing: normal;
  font-size: calc(25rem/16);
  line-height: calc(28rem/16);
  margin-bottom: calc(5rem/16);

  @media screen and (min-width: 992px) {
    font-size: calc(35rem/16);
    line-height: calc(39rem/16);
  }
}

.product-review .product-teaser__hl {
  font-size: calc(25rem/16);
  line-height: calc(29rem/16);
}

.product-teaser__body{
  padding: calc(25rem/16);
}

.product-teaser__desc{
  font-size: calc(18rem/16);
  line-height: calc(23/18);
  margin-bottom: calc(10rem/16);

  @media screen and (min-width: 768px) {
    line-height: calc(25/18);
  }
}

.product-review .product-teaser__desc, .product-review .product-teaser__body a.link-primary {
  font-size: calc(18rem/16);
  line-height: calc(25/18);
}

.product-teaser__reduced{
  font-size: calc(14rem/16);
  line-height: calc(23/18);
  text-decoration: line-through;

  margin-left: calc(30rem/16);
  margin-bottom: calc(-11rem/16);
  margin-top: calc(15rem/16);
  
  @media screen and (min-width: 768px) {
    line-height: calc(25/18);
    font-size: calc(18rem/16);
  }
}