.blog-slide{
  position: relative;
  padding: calc(40rem/16) 0;

  @media screen and (min-width: 768px){
      padding: calc(70rem/16) 0;
  }
}

.content-block+.blog-slide.content-block {
    @media screen and (min-width: 992px){
        margin-top: calc(230rem/16);
    }

    @media screen and (min-width: 768px){
        margin-top: calc(150rem/16);
    }
}

.blog-slide.content-block + .banner.content-block {
    margin-top: 0;

    @media screen and (min-width: 768px) {
        margin-top: 0;
    }

    @media screen and (min-width: 992px) {
        margin-top: 0;
    }
}

.blog-slide:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-light-grey);
    opacity: 0.7;
    z-index: 1;
}


.blog-slide__body{
    position: relative;
    z-index: 2;
}

.blog-slide__body .content-teaser__hl {
    text-transform: none;
    letter-spacing: normal;
}

.blog-slide .link-primary {
    color: var(--color-main);
}

.blog-slide .link-primary:hover {
    color: var(--color-main-dark);
}

.blog-slide__hl{
    @media screen and (min-width: 992px){
        margin-left: calc(285rem/16);
    }
}

.blog-slide__big-headline {
    position: absolute;
    top: calc((65rem/16)*-1);
    left: calc((30rem/16)*-1);
    z-index: 2;

    @media screen and (min-width: 992px) {
        top: calc((200rem/16)*-1);
    }

    @media screen and (min-width: 768px) {
        top: calc((140rem/16)*-1);
        left: calc((50rem/16)*-1);
    }
}

.blog-slide__img {
    position: absolute;
    display: none;
    bottom: calc((70rem/16)*-1);
    z-index: 10;

    @media screen and (min-width: 992px) {
        /* width: 22vw;
        max-width: calc(700rem/16); */
        display: block;
    }

    @media screen and (min-width: 768px) {
        left: calc((50rem/16)*-1);
        /* width: calc(350rem/16); */
    }
}

.blog-slide .transparent {
    opacity: 0.3;
}

.blog-slide .slick-slide {
    transition: opacity 300ms ease;
}

.blog-slide .teaser-slider .slick-track {
    width: 100%;
}

.blog-slide .content-teaser__img:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, rgba(177,145,101,0.9) 0%, rgba(177,145,101,0) 80%);
    z-index: 1;
    opacity: 0;

    -webkit-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
}

.blog-slide .content-teaser:hover .content-teaser__img:after {
    opacity: 0.5;
}

.blog-slider button.slick-prev {
    right: calc(50rem/16);
    border-right: 2px solid var(--color-main);
    height: calc(40rem/16);
}

.blog-slider button.slick-next {
    right: 0;
    height: calc(40rem/16);
}