.rolex-footer{
  margin-top: calc(-10rem/16);
  @media screen and (min-width: 992px){
    margin-top: calc(120rem/16);
  }

  @media screen and (min-width: 768px){
    margin-top: calc(100rem/16);
  }
}
.rolex-footer__logo img{
  width: calc(70rem/16);
  
  @media screen and (min-width: 768px){
    width: calc(88rem/16);
    margin-bottom: calc(20rem/16);
  }
  
  @media screen and (max-width: 767px){
     margin-top: calc(30rem/16);
  }
}

.rolex-footer__links{
  @media screen and (min-width: 768px){
    padding: calc(15rem/16) 0;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
  }
}

.rolex-footer__links .list-inline-item.active a{
  color: var(--color-primary);
    font-weight: 600;
}

.rolex-footer__links a{
  color: var(--color-rolex-default-light);
  font-size: calc(14rem/16);

  @media screen and (min-width: 768px){
    font-size: calc(12rem/16);
  }
  
  @media screen and (max-width: 767px){
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(47rem/16);
    padding: 11px 10px;
    border-bottom: 1px solid #e6e6e6;
  }
}


.rolex-footer__links a:hover{
  color: var(--color-rolex-default);
}

.rolex-footer__links .list-inline-item:not(:last-child){
  @media screen and (min-width: 768px){
    margin-right: calc(30rem/16);
  }
}

.rolex-footer__links .list-inline-item{
  @media screen and (max-width: 767px){
      height: calc(47rem/16);
    display: block;
    text-align: center;
  }
}


.rolex-go-to-top{
    width: calc(40rem/16);
    height: calc(40rem/16);
  background-color: var(--color-rolex-primary);
  color: #fff;
  display: inline-block;
  border-radius: 50%;
  position: relative;
    margin-top: calc(30rem/16);

}

.rolex-go-to-top:hover{
  color: #fff;
}

.rolex-go-to-top .icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(90deg);
  font-size: calc(12rem/16);

  @media screen and (min-width: 768px){
    font-size: calc(14rem/16);
  }
}