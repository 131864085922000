.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}.custom-checkbox:after {
     clear: both;
 }
.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    position: relative;
    float: left;
    margin-right: calc(10rem/16);
    overflow: hidden;
    box-shadow: 0 calc(5rem/16) calc(10rem/16) 0 rgba(0,0,0,0.1);
    background: #ffffff;
    width: calc(20rem/16);
    height: calc(20rem/16);
    margin-top: .1em;
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(7rem/16);
    color: var(--color-primary);
    visibility: hidden;
    font-weight: bold;
}
.custom-checkbox__box:hover {
    cursor: pointer;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    font-size: calc(15rem/16);
    position: absolute;
    top: calc(3rem/16);
}


.rolex-contact-form .custom-checkbox{
    margin-bottom: calc(14rem/16);
}

.rolex-contact-form__checkboxes .custom-checkbox__box {
    border: solid 1px var(--color-rolex-grey);
    box-shadow: none;
    margin-top: 0;
    width: calc(15rem/16);
    height: calc(15rem/16);
    flex-shrink: 0;
}
.rolex-contact-form__checkboxes .custom-checkbox__box:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: var(--color-rolex-primary);
    font-size: calc(3rem/16);
    color: var(--color-rolex-primary);
    transform: none;
}

.rolex-contact-form__checkboxes .custom-checkbox__text {
    font-size: calc(12rem/16);
    position: absolute;
    top: calc(-3rem/16);
}

.rolex-contact-form__checkboxes .custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-rolex-grey);
}

.custom-checkbox.custom-checkbox--rolex .custom-checkbox__box{
    box-shadow: none;
    border: calc(1rem /16) solid var(--color-rolex-default);
    margin-top: calc(5rem / 16);
}