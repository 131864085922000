.overlay-menu:before{
  background: #3B383E;
}

.overlay-menu__bottom{
  bottom: calc(40rem/16);
}

.overlay-menu__main li.is-active>a,
.overlay-menu__main li>a:focus,
.overlay-menu__main li>a:hover,
.overlay-menu__bottom li>a:focus,
.overlay-menu__bottom li>a:hover{
  color: var(--color-dark);
}


@media screen and (min-width: 768px){
  .overlay-menu__main li>a:before{
    background: var(--color-dark);
  }
}
