.is-rolex .text-primary{
  color: #b19165!important;
}

.is-rolex .content-heading__headline{
  color: var(--color-rolex-default);
  text-transform: uppercase ;
  font-size: calc(31rem/16);
  position: relative;
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 0;

  @media screen and (min-width: 768px){
    font-size: calc(36rem/16);
  }
}

.is-rolex .content-heading__preheadline{
  text-transform: uppercase ;
  font-size: calc(15.6rem/16);
  line-height: 1;
  margin-bottom: calc(8rem/16);

  @media screen and (min-width: 768px){
    font-size: calc(18rem/16);
    margin-bottom: calc(12rem/16);
  }
}



.is-rolex .content-image-and-text__headline{
  font-size: calc(26rem/16);
  margin-bottom: calc(20rem/16);
  text-transform: uppercase;

  @media screen and (min-width: 768px){
    margin-bottom: calc(30rem/16);
    font-size: calc(30rem/16);
  }
}


.is-rolex .content-image-and-text__wysiwyg,
.is-rolex .content-heading__wysiwyg{
  font-size: calc(13rem/16);
  line-height: 1.8;
  margin-top: calc(16rem/16);

  @media screen and (min-width: 768px){
    font-size: calc(14.4rem/16);
    margin-top: calc(30rem/16);
  }
}

.is-rolex .content-image-and-text__preheadline{
  font-size: calc(15rem/16);
}

.is-rolex .link-primary{
  font-size: calc(16rem/16);
}

.is-rolex .content-block, .content-block + .content-block,
.is-rolex .pimcore_area_content + .pimcore_area_content > .content-block {
  margin-top: calc(40rem/16);

  @media screen and (min-width: 768px){
    margin-top: calc(50rem/16);
  }

  @media screen and (min-width: 992px){
    margin-top: calc(100rem/16);
  }
}
