.content-teaser{
    background-color: #fff;
    height: 100%;
}

.content-teaser__hl {
    word-wrap: break-word;
}

.content-teaser__hl.no-upper {
    text-transform: none;
}

.content-teaser__img {
    position: relative;
}

.content-teaser__body{
    padding: calc(25rem/16);
}

.blog-slide .content-teaser__body > .content-teaser__hl:first-child {
    margin-top: calc(25rem/16);
}

.content-teaser__desc{
    font-size: calc(15rem/16);
    line-height: calc(25/15);
}

.content-teaser__author {
    font-size: calc(15rem/16);
    line-height: calc(17/15);
    margin-bottom: calc(10rem/16);
}

.content-teaser__author a {
    color: var(--color-main-middle);
    font-weight: bold;
}

.content-teaser__author a:hover {
    color: var(--color-main-dark);
}

.date-badge {
    padding: calc(5rem/16) calc(15rem/16);
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: calc(15rem/16);
    z-index: 10;
    text-transform: capitalize;
    background-color: var(--color-dark);
    color: #fff;
}

.cat-news .date-badge{
    background-color: var(--color-main-dark);
    color: #fff;
}

.cat-event .date-badge{
    background-color: var(--color-primary-dark);
    color: #fff;
}

.content-teaser__img:after {

    @media screen and (min-width: 992px){
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to top, rgba(59,56,62,0.9) 0%, rgba(59,56,62,0) 50%);
        z-index: 1;
        opacity: 0;

        transition: opacity 0.3s ease-in;
    }

}

.content-teaser:hover .content-teaser__img:after {
    @media screen and (min-width: 992px){
        opacity: 0.5;
    }
}

.content-teaser .link-primary{
    font-size: calc(15rem/16);
}

.search-result .content-teaser .link-primary{
    word-break: break-word;
}