.img-teaser-grid {
    padding-left: calc((20rem/16)*-1);
    padding-right: calc((20rem/16)*-1);
}

.img-teaser-grid__headline, .img-teaser-grid__wysiwyg {
    text-align: center;
}

.img-teaser-grid__wysiwyg {
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 992px){
        margin-bottom: calc(70rem/16);
    }
}

.img-teaser {
    position: relative;
}

.img-teaser:before,
.img-teaser:after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg,rgba(59,56,62,.8) 0,rgba(59,56,62,0) 60%);
    transition: all 300ms ease;
}

.brand .img-teaser:before,
.brand .img-teaser:after,
.brand .img-teaser:hover:after {
    background: none;
}

.img-teaser:before{
    z-index: 2;
    opacity: 1;
}

.img-teaser:after {
    z-index: 5;
    opacity: 0;
}

.img-teaser:hover:before {
    opacity: 0.5;
}

.img-teaser:hover:after {
    opacity: 1;
    background: linear-gradient(to top, rgba(59,56,62,0.8) 0%, rgba(59,56,62,0) 60%);
    cursor: pointer;
}

.brand .img-teaser:hover {
    opacity: 0.6;
    transition: opacity 300ms ease;
}

.img-teaser__hl{
    position: absolute;
    margin-bottom: calc(15rem/16);
    bottom: 0;
    left:  0;
    color: #fff;
    z-index: 10;
    padding: 0 calc(15rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        padding: 0 calc(20rem/16);
    }

    @media screen and (min-width: 992px){
        font-size: calc(30rem/16);
    }
}

.brand .img-teaser__hl {
    color: var(--color-primary);
}