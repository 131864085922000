.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
}
.wysiwyg a:not(.btn):hover,
.wysiwyg a:not(.btn):focus,
.wysiwyg a:not(.btn):active {
    color: var(--color-primary);
    text-decoration: underline;
}

.wysiwyg ul, .wysiwyg ol {
    padding-left: 0;
}

.wysiwyg ul li {
    list-style-type: none;
    position: relative;
    padding-left: calc(20rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(10rem/16);
    }
}

.wysiwyg ul li:before {
    color: var(--color-primary-dark);
    font-weight: bold;
    content: "• ";
    position: absolute;
    left: 0;
    font-size: calc(25rem/16);
    line-height: calc(25rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(30rem/16);
        line-height: calc(30rem/16);
    }
}


.wysiwyg ol li {
    counter-increment: list;
    list-style-type: none;
    position: relative;
    padding-left: calc(30rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(10rem/16);
    }
}

.wysiwyg ol li:before {
    color: var(--color-primary-dark);
    font-weight: bold;
    content: counter(list) ".";
    left: 0;
    position: absolute;

    @media screen and (min-width: 768px){
        left: 0;
    }
}

.wysiwyg .h4.detail-section {
    text-transform: none;
    letter-spacing: normal;
    font-size: calc(18rem/16);
    font-family: var(--font-default);
    font-weight: bold;
}