.skd-widget.skd-widget .skd-widget-btn-primary,
.skd-widget.skd-widget .skd-widget-portal-search .skd-widget-active.skd-widget-btn,
.skd-widget.skd-widget .skd-widget-portal-search .skd-widget-active,
.skd-datepicker-container.skd-datepicker-container .skd-date-range,
.skd-datepicker-container.skd-datepicker-container .skd-date-range.skd-widget-day,
.skd-datepicker-container.skd-datepicker-container .skd-date-range.skd-widget-day:hover,
.skd-datepicker-container.skd-datepicker-container .skd-date-range:hover,
.skd-datepicker-container.skd-datepicker-container .skd-date-check-out,
.skd-datepicker-container.skd-datepicker-container .skd-date-check-in,
.skd-widget.skd-widget .skd-widget-btn-primary:active,
.skd-widget.skd-widget .skd-widget-btn-primary.active,
.skd-widget.skd-widget .skd-widget-btn-primary.skd-widget-active,
.skd-widget.skd-widget .skd-widget-btn-danger,
.skd-widget .skd-dsr-view .skd-property-hero-control .skd-widget-active a,
.skd-offer-list-widget.skd-offer-list-widget .skd-package .skd-package-amount-and-details .skd-package-details-btn,
.skd-offer-list-widget.skd-offer-list-widget .container-progress ul.skd-breadcrumbs li.skd-active:after,
.skd-offer-list-widget.skd-offer-list-widget .container-progress ul.skd-breadcrumbs li:not(.skd-widget-disabled):hover:after,
.skd-widget.skd-widget .skd-dsr-view .skd-property-hero-control .skd-widget-active a,
.skd-loading-pulse.skd-loading-pulse>div,
.skd-offer-list-widget.skd-offer-list-widget .container-progress ul.skd-breadcrumbs li.skd-active,
.skd-offer-list-widget.skd-offer-list-widget .container-progress ul.skd-breadcrumbs li:not(.skd-widget-disabled):hover,
.skd-package-details.skd-package-details .skd-container-rates .skd-btn-checkout,
.skd-room-details.skd-room-details .skd-container-rates .skd-btn-checkout,
.skd-offer-list-widget.skd-offer-list-widget .skd-service-entry .skd-service-controls .skd-widget-input-group .skd-amount-tag,
.skd-offer-list-widget.skd-offer-list-widget .skd-service-entry .skd-service-controls .skd-widget-input-group .skd-remove-service,
.skd-offer-list-widget.skd-offer-list-widget .skd-service-entry .skd-service-controls .skd-widget-input-group .skd-add-service,
.skd-offer-list-widget.skd-offer-list-widget .skd-service-entry .skd-service-selection-mark,
.skd-offer-list-widget.skd-offer-list-widget .skd-service-entry .skd-service-selection-mark:before,
.skd-offer-list-widget.skd-offer-list-widget .skd-service-entry .skd-service-selection-mark:after
{
    background: var(--color-primary-dark);
    border-color: var(--color-primary-dark);
    color: #ffffff;
}

.skd-datepicker.skd-datepicker table tr td.skd-widget-day:hover,
.skd-datepicker.skd-datepicker table tr td.skd-widget-day.focused,
.skd-datepicker-container.skd-datepicker-container .skd-date-trail.skd-trail-enabled,
.skd-datepicker-container.skd-datepicker-container .skd-date-trail.skd-trail-enabled:hover,
.skd-widget.skd-widget .skd-widget-btn-primary:hover,
.skd-widget.skd-widget .skd-widget-btn-primary:focus,
.skd-widget.skd-widget .skd-widget-btn-primary.disabled,
.skd-widget.skd-widget .skd-widget-btn-primary[disabled],
.skd-widget.skd-widget .skd-widget-btn-primary.skd-widget-disabled,
.skd-widget.skd-widget .skd-widget-btn-primary:hover,
.skd-widget.skd-widget .skd-widget-portal-search .skd-widget-active.skd-widget-btn:hover,
.skd-widget.skd-widget .skd-widget-btn-danger.skd-widget-active,
.skd-widget.skd-widget .skd-widget-btn-danger:active,
.skd-widget.skd-widget .skd-widget-btn-danger:focus,
.skd-widget.skd-widget .skd-widget-btn-danger:hover,
.skd-widget.skd-widget .skd-widget-open .skd-widget-dropdown-toggle.skd-widget-btn-danger
{
    background: #c0a072;
    border-color: #c0a072;
    color: #ffffff;
}

.skd-widget.skd-widget .skd-amenities-icons .skd-amenity-wrap .skd-iconmoon,
.skd-widget.skd-widget .skd-dsr-view .skd-property-amenities .skd-amenity-list .skd-iconmoon,
.skd-offer-list-widget.skd-offer-list-widget .container-progress ul.skd-breadcrumbs li.skd-active:before,
.skd-offer-list-widget.skd-offer-list-widget .container-progress ul.skd-breadcrumbs li:not(.skd-widget-disabled):hover:before,
.skd-widget.skd-widget .skd-dsr-view .skd-property-address.skd-map-address:hover .fa,
.skd-widget.skd-widget .skd-slides-expand:hover,
.skd-container-policies.skd-container-policies .skd-policy h3,
.skd-offer-list-widget.skd-offer-list-widget .skd-wrapper-results .skd-share-row a,
.skd-offer-list-widget.skd-offer-list-widget .skd-wrapper-results .skd-share-row a:hover,
.skd-package-title.skd-package-title .skd-occupancy-icons,
.skd-room-title.skd-room-title .skd-occupancy-icons,
.skd-price-wrapper.skd-price-wrapper
{
    color: var(--color-primary-dark);
}
.skd-properties-list-view.skd-properties-list-view .skd-property-item {
    border-color: var(--color-primary-dark);
}

.skd-widget.skd-widget .skd-widget-panel-default>.skd-widget-panel-heading {
    background-color: #3b393e;
    border-color: #3b393e;
    color: #ffffff;
}