.slick-dots{
  list-style: none;
  padding-left: 0;
  text-align: center;
}

.slick-dots>li{
  display: inline-block;
  margin: 0 calc(7rem/16);
  width: calc(10rem/16);
  height: calc(10rem/16);
}

.slick-dots>li button{
  box-shadow: none;
  color: transparent;
  line-height: 0;
  background: #fff;
  width: calc(10rem/16);
  height: calc(10rem/16);
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  border: calc(1rem/16) solid var(--color-primary);
}

.slick-dots>li.slick-active button{
  background: var(--color-primary);
  width: calc(12rem/16);
  height: calc(12rem/16);
}

.slider-item-same-height .slick-track{
    display: -webkit-box;    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -webkit-flex;  /* Safari 6.1 + */
    display: flex;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.slider-item-same-height .slick-slide{
    display: -webkit-box;
    height: auto;
}

.slider-item-same-height .slick-slide>div{
    height: 100%;
    width: 100%;
}


.slick-arrow.slick-disabled{
  opacity: 0.3;
  cursor: auto;
}

.slider-arrows .btn>.icon{
  vertical-align: calc(-8rem/16);
}


.slick-titles a,
.slick-titles .slick-titles__button{
  background: #fff;
  border: none;
  width: 100%;

  font-family: var(--font-bold);
  font-size: calc(13rem/16);
  line-height: calc(22/20);
  border-radius: 0;
  letter-spacing: calc(3rem/16);

  @media screen and (min-width: 768px){
    font-size: calc(18rem/16);
  }

  @media screen and (max-width: 767px){
    letter-spacing: calc(2.5rem/16);
    padding: calc(13rem/16) calc(5rem/16);
    display: inline-block;
    text-align: center;
  }
}

.slick-titles .slick-arrow {
  @media screen and (max-width: 767px){
    font-size: 13px;
    width: 25px;
    height: 40px;
    padding: 6px 5px;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.75);
    color: var(--color-dark);
  }

}

.slick-titles .btn.slick-arrow:focus,
.slick-titles .btn.slick-arrow:active{
  background-color: rgba(255, 255, 255, 0.75) !important;
  color: var(--color-dark);
  border-color: transparent;
  box-shadow: none;
}

.slick-titles .slick-arrow.slick-next {
  @media screen and (max-width: 767px){
    right: -10px;
  }
}

.slick-titles .slick-arrow.slick-prev{
  @media screen and (max-width: 767px){
    left: -10px;
  }
}