.product-review.content-block + .banner.content-block {
    margin-top: 0;

    @media screen and (min-width: 768px) {
        margin-top: 0;
    }

    @media screen and (min-width: 992px) {
        margin-top: 0;
    }
}

.product-review {
    padding-bottom: calc(30rem / 16);

    @media screen and (min-width: 992px) {
        padding-bottom: calc(50rem / 16);
    }
}

.label-top .control-label {
    position: relative;
    color: var(--color-text-default);
    font-family: var(--font-bold);
    left: 0;
    transition: none;
}

.label-top .form-control {
    padding-top: 0;
}

.label-top .select:before {
    transform: translateY(-15%);
}

.form-group.label-top {
    @media screen and (max-width: 767px) {
        margin-bottom: calc(30rem / 16);
    }
}


.product-review__meta {
    font-size: calc(16rem/16);
    color: #5F5C62;
    font-family: var(--font-bold);
}

.form-group.filter-sort {
    margin-bottom: 0;
}

.filter-sort label {
    display: none;
}

.filter-sort .form-control {
    background: none;
    box-shadow: none;
    padding: 0;
    line-height: 1;
    font-size: calc(16rem/16);
}

.filter-sort .select:before {
    right: 0;
}

.form-group.filter-sort select.form-control {
    height: calc(18rem/16)!important;
    padding-right: calc(20rem/16);
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
    }
}

@keyframes spinner {
  0% {
      transform: rotate(0deg);
  }
  50% {
      transform: rotate(180deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.filter-form__loading {
    position: relative;
    z-index: 2;
    height: calc(300rem/16);
    transform: translate(calc((100rem/16)*-1), calc((100rem/16)*-1)) scale(1) translate(calc(100rem/16), calc(100rem/16));
}
.filter-form__loading div {
    position: absolute;
    animation: spinner 1s linear infinite;
    width: calc(80rem/16);
    height: calc(80rem/16);
    top: 0;
    left: calc(50% - calc(40rem/16));
    border-radius: 50%;
    box-shadow: 0 calc(4rem/16) 0 0 #d9c6a7;
    transform-origin: calc(40rem/16) calc(41rem/16);

    @media screen and (min-width: 768px){
        width: calc(160rem/16);
        height: calc(160rem/16);
        left: 45%;
        transform-origin: calc(80rem/16) calc(82rem/16);
    }
}

.is-loading {
    display: none;
}