.questions .btn:not(:last-child) {
    margin-right: 0;

    @media screen and (min-width: 768px) {
        margin-right: calc(20rem / 16);
    }
}

.questions .btn {
    margin-top: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: 0;
    }
}

.questions p {
    font-size: calc(18rem/16);
    line-height: calc(25/18);
}