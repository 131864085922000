/*Base-Styling for HTML-Elements*/

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}

a:focus { outline:0; }

a:focus {
    box-shadow: 0 0 0 1px rgba(77, 143, 122, .3);
}

th {
    font-family: var(--font-bold);
    font-weight: normal;
}
textarea {
    resize: vertical;
}

label {
    font-weight: normal;
    margin-bottom: 0;
}
ul,
ol,
dl {
    margin-bottom: 0;
}
dt {
    font-weight: normal;
}

*::-moz-selection {
    color: #ffffff;
    background-color:  var(--color-primary);
}
*::selection {
    color: #ffffff;
    background-color: var(--color-primary);
}